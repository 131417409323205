export const ACTIONS = {
  GET_NOTIFICATIONS: 'NOTIFICATIONS/GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS/GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'NOTIFICATIONS/GET_NOTIFICATIONS_FAILURE',

  GET_UNREAD_NOTIFICATIONS_COUNT: 'NOTIFICATIONS/GET_UNREAD_NOTIFICATIONS_COUNT',
  GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS: 'NOTIFICATIONS/GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS',
  GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE: 'NOTIFICATIONS/GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE',

  SET_NOTIFICATION_READ: 'NOTIFICATIONS/SET_NOTIFICATION_READ',
  SET_NOTIFICATION_READ_SUCCESS: 'NOTIFICATIONS/SET_NOTIFICATION_READ_SUCCESS',
  SET_NOTIFICATION_READ_FAILURE: 'NOTIFICATIONS/SET_NOTIFICATION_READ_FAILURE',

  SET_NOTIFICATIONS_AS_READ: 'NOTIFICATIONS/SET_NOTIFICATIONS_AS_READ',
  SET_NOTIFICATIONS_AS_READ_SUCCESS: 'NOTIFICATIONS/SET_NOTIFICATIONS_AS_READ_SUCCESS',
  SET_NOTIFICATIONS_AS_READ_FAILURE: 'NOTIFICATIONS/SET_NOTIFICATIONS_AS_READ_FAILURE',

  REMOVE_NOTIFICATION: 'NOTIFICATIONS/REMOVE_NOTIFICATION',
  REMOVE_NOTIFICATION_SUCCESS: 'NOTIFICATIONS/REMOVE_NOTIFICATION_SUCCESS',
  REMOVE_NOTIFICATION_FAILURE: 'NOTIFICATIONS/REMOVE_NOTIFICATION_FAILURE',

  REMOVE_NOTIFICATIONS: 'NOTIFICATIONS/REMOVE_NOTIFICATIONS',
  REMOVE_NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS/REMOVE_NOTIFICATIONS_SUCCESS',
  REMOVE_NOTIFICATIONS_FAILURE: 'NOTIFICATIONS/REMOVE_NOTIFICATIONS_FAILURE',

  NEW_NOTIFICATION: 'NOTIFICATIONS/NEW_NOTIFICATION',

  SET_NOTIFICATION_FILTER_SUCCESS: 'NOTIFICATIONS/SET_NOTIFICATION_FILTER_SUCCESS',
  SET_NOTIFICATION_FILTER_FAILURE: 'NOTIFICATIONS/SET_NOTIFICATION_FILTER_FAILURE',

  SET_NOTIFICATION_PAGINATION_SUCCESS: 'NOTIFICATIONS/SET_NOTIFICATION_PAGINATION_SUCCESS',
  SET_NOTIFICATION_PAGINATION_FAILURE: 'NOTIFICATIONS/SET_NOTIFICATION_PAGINATION_FAILURE',
};
