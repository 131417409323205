import agent from 'services/http';

/**
 * GET /api/notifications/infos.
 * Get the infos for notifications.
 *
 * @async
 * @returns {object} - Object containing infos.
 */
export async function getInfos() {
  const { body } = await agent.get('/api/notifications/infos');
  return body;
}
