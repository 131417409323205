import { keyBy, omit } from 'lodash';
import { combineReducers } from 'redux';

import { NOTIFICATION_FILTERS, NOTIFICATION_PAGINATION_ITEM_NUMBER } from 'config/constants';
import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_NOTIFICATIONS_SUCCESS:
        return {
          ...keyBy(action.notifications, 'id'),
        };

      case ACTIONS.SET_NOTIFICATION_READ_SUCCESS:
        return {
          ...state,
          [action.notification.id]: action.notification,
        };

      case ACTIONS.SET_NOTIFICATIONS_AS_READ_SUCCESS: {
        const notifications = action.notifications.reduce(
          (acc, notification) => {
            acc[notification.id] = notification;
            return acc;
          }, {},
        );
        return {
          ...state,
          ...notifications,
        };
      }

      case ACTIONS.REMOVE_NOTIFICATION_SUCCESS:
        return omit(state, [action.notificationId]);

      case ACTIONS.REMOVE_NOTIFICATIONS_SUCCESS: {
        return omit(state, action.notificationIds);
      }

      default:
        return state;
    }
  },

  totalCount: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.GET_NOTIFICATIONS_SUCCESS:
        return action.count;

      default:
        return state;
    }
  },

  unreadCount: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
        return action.count;

      default:
        return state;
    }
  },

  filter: (state = NOTIFICATION_FILTERS.ALL, action) => {
    switch (action.type) {
      case ACTIONS.SET_NOTIFICATION_FILTER_SUCCESS:
        return action.filter;
      default:
        return state;
    }
  },

  limit: (state = NOTIFICATION_PAGINATION_ITEM_NUMBER, action) => {
    switch (action.type) {
      case ACTIONS.SET_NOTIFICATION_PAGINATION_SUCCESS:
        return action.limit;
      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getNotifications: (state = false, action) => {
      switch (action.type) {
        case ACTIONS.GET_NOTIFICATIONS:
          return true;

        case ACTIONS.GET_NOTIFICATIONS_FAILURE:
        case ACTIONS.GET_NOTIFICATIONS_SUCCESS:
          return false;

        default:
          return state;
      }
    },

    getUnreadNotificationsCount: (state = false, action) => {
      switch (action.type) {
        case ACTIONS.GET_UNREAD_NOTIFICATIONS_COUNT:
          return true;

        case ACTIONS.GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE:
        case ACTIONS.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
          return false;

        default:
          return state;
      }
    },

    setNotificationRead: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.SET_NOTIFICATION_READ:
          return {
            ...state,
            [action.notificationId]: true,
          };

        case ACTIONS.SET_NOTIFICATION_READ_FAILURE:
          return omit(state, [action.notificationId]);

        case ACTIONS.SET_NOTIFICATION_READ_SUCCESS:
          return omit(state, [action.notification.id]);

        default:
          return state;
      }
    },

    setNotificationsAsRead: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.SET_NOTIFICATIONS_AS_READ: {
          const notificationIds = action.notificationIds.reduce(
            (acc, notificationId) => {
              acc[notificationId] = true;
              return acc;
            }, {},
          );
          return {
            ...state,
            ...notificationIds,
          };
        }
        case ACTIONS.SET_NOTIFICATIONS_AS_READ_FAILURE:
          return omit(state, action.notificationIds);

        case ACTIONS.SET_NOTIFICATIONS_AS_READ_SUCCESS: {
          const notificationIds = action.notifications.map(notification => notification.id);
          return omit(state, notificationIds);
        }
        default:
          return state;
      }
    },

    removeNotification: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.REMOVE_NOTIFICATION:
          return {
            ...state,
            [action.notificationId]: true,
          };

        case ACTIONS.REMOVE_NOTIFICATION_FAILURE:
        case ACTIONS.REMOVE_NOTIFICATION_SUCCESS:
          return omit(state, [action.notificationId]);

        default:
          return state;
      }
    },

    removeNotifications: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.REMOVE_NOTIFICATIONS: {
          const notificationIds = action.notificationIds.reduce(
            (acc, notificationId) => {
              acc[notificationId] = true;
              return acc;
            }, {},
          );
          return {
            ...state,
            ...notificationIds,
          };
        }

        case ACTIONS.REMOVE_NOTIFICATIONS_FAILURE:
        case ACTIONS.REMOVE_NOTIFICATIONS_SUCCESS: {
          return omit(state, action.notificationIds);
        }
        default:
          return state;
      }
    },
  }),
});
