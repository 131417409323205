import { validationStatuses } from 'redux/products/constants';
import { userIsManager } from './utils';

/**
 * The user is allowed to edit the product.
 *
 * @param {string} userExternalId - External ID of user.
 * @param {object} product - The product.
 * @param {object} community - The product community.
 * @param {object} domain - The product domain.
 * @param {object} businessUnit - The product business unit.
 * @param {boolean} isPrivileged - Is a superuser?
 * @returns {boolean} - True if user has editing rights.
 */
export const userCanEditProduct = (
  userExternalId,
  product,
  community = {},
  domain = {},
  businessUnit = {},
  isPrivileged,
) => {
  if (!product || !userExternalId) {
    return false;
  }

  // if the product requires community architect validation and the user is the community architect
  if (
    product.validation?.status === validationStatuses.PCA
    && userExternalId === community.communityArchitect
  ) {
    return true;
  }

  const rolesThatCanEdit
    = [
      // Or you are a member of the product team.
      ...product.teamMembers.map(tm => tm.externalId),
      // Or you are a community leader.
      community.businessCommunityLeaderId,
      community.digitalCommunityLeaderId,
    ];

  // Product is global and you are a domain leader.
  if (product.isGlobal || product.isMaintainedByPlatform) {
    rolesThatCanEdit.push(
      domain.businessDomainLeader,
      domain.digitalDomainLeader,
    );
  } else {
    // Product is local and you are a BU architect
    rolesThatCanEdit.push(
      businessUnit.businessUnitArchitect,
    );
  }

  return isPrivileged
    || rolesThatCanEdit.includes(userExternalId)
    // if user is the author and there is no team or the product is still a draft
    || (
      (
        product.teamMembers?.length === 0
        || product.isDraft
      )
      && userExternalId === product.author);
};

/**
 * The user is allowed to edit the product.
 *
 * @param {string} userExternalId - External ID of user.
 * @param {object} product - The product.
 * @returns {boolean} - True if user has editing rights.
 */
export const userCanDeleteProduct = (
  userExternalId,
  product,
) => {
  if (!product || !userExternalId) {
    return false;
  }

  const isMember
    = (product?.teamMembers || [])
    .map(el => el.externalId)?.includes(userExternalId);

  const isAuthor = product?.author === userExternalId;
  const productHasEmptyTeam = product?.teamMembers.length === 0;

  if (!isMember && !(isAuthor && productHasEmptyTeam)) {
    return false;
  }

  return true;
};

/**
 * The user is allowed to edit the galaxis informations of the product.
 *
 * @param {string} userExternalId - External ID of user.
 * @param {object} product - The product.
 * @param {object} community - The product community.
 * @param {object} domain - The product domain.
 * @returns {boolean} - True if user has editing galaxis informations rights.
 */
export const userCanEditProductAdditionalInfo = (
  userExternalId,
  product,
  community = {},
  domain = {},
) => {
  if (!product || !userExternalId) {
    return false;
  }

  return [
    // Or you are a leader of the product.
    ...product.teamMembers.filter(tm => tm.role.isProductLeader).map(tm => tm.externalId),
    // Or you are a community leader.
    community.businessCommunityLeaderId,
    community.digitalCommunityLeaderId,
    // Or you are a domain leader.
    domain.businessDomainLeader,
    domain.digitalDomainLeader,
  ].includes(userExternalId)
  // if user is the author and there is no team or the product is still a draft
  || (
    (
      product.teamMembers?.length === 0
      || product.isDraft
    )
    && userExternalId === product.author);
};

/**
 * The user is allowed to add a component to the product.
 *
 * @param {object} user - User object.
 * @param {object} product - The product.
 * @param {boolean} isPrivileged - Is a superuser?
 * @returns {boolean} - True if user is Product Leader or has privileged (TANGRAM team).
 */
export const userCanAddComponent = (
  user,
  product,
  isPrivileged,
) => {
  if (!product || !user?.externalId) {
    return false;
  }

  return isPrivileged || userIsManager(user, product);
};

export const isGlobal = product => !!product.isGlobal || !!product.isMaintainedByPlatform;

export const isLocal = product => !product.isGlobal && !product.isMaintainedByPlatform;
