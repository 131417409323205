import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { ACTIONS } from './constants';
import { ACTIONS as REPLIES_ACTIONS } from './replies/constants';

export default combineReducers({
  allSuggestionsCount: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.GET_ALL_SUGGESTIONS_COUNT_SUCCESS:
        return action.count;
      default:
        return state;
    }
  },

  countByProduct: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCT_SUGGESTIONS_SUCCESS:
        return {
          ...state,
          [action.productId]: action.count,
        };

      case ACTIONS.CREATE_PRODUCT_SUGGESTION_SUCCESS:
        return {
          ...state,
          [action.productId]: state[action.productId] + 1,
        };

      case ACTIONS.DELETE_PRODUCT_SUGGESTION_SUCCESS:
        return {
          ...state,
          [action.productId]: state[action.productId] - 1,
        };

      default:
        return state;
    }
  },

  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCT_SUGGESTIONS_SUCCESS: {
        const newSuggestions = keyBy(action.suggestions, 'id');

        return {
          ...state,
          [action.productId]: action.preserve ? {
            ...state[action.productId],
            ...newSuggestions,
          } : newSuggestions,
        };
      }

      case ACTIONS.GET_PRODUCT_SUGGESTION_SUCCESS:
      case ACTIONS.PATCH_PRODUCT_SUGGESTION_SUCCESS:
      case ACTIONS.SET_PRODUCT_SUGGESTION:
        return {
          ...state,
          [action.productId]: {
            ...state[action.productId],
            [action.suggestion.id]: action.suggestion,
          },
        };

      case ACTIONS.CREATE_PRODUCT_SUGGESTION_SUCCESS:
        return {
          ...state,
          [action.productId]: {
            [action.suggestion.id]: action.suggestion, // Set the new suggestion in first position.
            ...state[action.productId],
          },
        };

      case ACTIONS.VOTE_PRODUCT_SUGGESTION_SUCCESS:
        return {
          ...state,
          [action.productId]: {
            ...state[action.productId],
            [action.suggestion.id]: {
              ...state[action.productId]?.[action.suggestion.id],
              helpful: action.suggestion.helpful,
            },
          },
        };

      case ACTIONS.DELETE_PRODUCT_SUGGESTION_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        const { [action.suggestionId]: _, ...rest } = state[action.productId];
        return {
          ...state,
          [action.productId]: {
            ...rest,
          },
        };

      case REPLIES_ACTIONS.CREATE_REPLY_SUCCESS: {
        const { productId, suggestionId, replyId } = action;
        return replyId ?
          state
          : {
            ...state,
            [productId]: {
              ...state[productId],
              [suggestionId]: {
                ...state[productId][suggestionId],
                directRepliesCount: (state[productId][suggestionId].directRepliesCount || 0) + 1,
              },
            },
          };
      }

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getAllSuggestionsCount: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_ALL_SUGGESTIONS_COUNT:
          return state + 1;
        case ACTIONS.GET_ALL_SUGGESTIONS_COUNT_SUCCESS:
        case ACTIONS.GET_ALL_SUGGESTIONS_COUNT_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getProductSuggestions: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_PRODUCT_SUGGESTIONS:
          return state + 1;
        case ACTIONS.GET_PRODUCT_SUGGESTIONS_SUCCESS:
        case ACTIONS.GET_PRODUCT_SUGGESTIONS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getProductSuggestion: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_PRODUCT_SUGGESTION:
          return state + 1;
        case ACTIONS.GET_PRODUCT_SUGGESTION_SUCCESS:
        case ACTIONS.GET_PRODUCT_SUGGESTION_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    createProductSuggestion: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.CREATE_PRODUCT_SUGGESTION:
          return state + 1;
        case ACTIONS.CREATE_PRODUCT_SUGGESTION_SUCCESS:
        case ACTIONS.CREATE_PRODUCT_SUGGESTION_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    voteProductSuggestion: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.VOTE_PRODUCT_SUGGESTION:
          return state + 1;
        case ACTIONS.VOTE_PRODUCT_SUGGESTION_SUCCESS:
        case ACTIONS.VOTE_PRODUCT_SUGGESTION_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    patchProductSuggestion: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.PATCH_PRODUCT_SUGGESTION:
          return state + 1;
        case ACTIONS.PATCH_PRODUCT_SUGGESTION_SUCCESS:
        case ACTIONS.PATCH_PRODUCT_SUGGESTION_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    deleteProductSuggestion: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.DELETE_PRODUCT_SUGGESTION:
          return state + 1;
        case ACTIONS.DELETE_PRODUCT_SUGGESTION_SUCCESS:
        case ACTIONS.DELETE_PRODUCT_SUGGESTION_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
