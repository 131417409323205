import '@babel/polyfill';

import bootstrapPolyfills from 'bootstrap/polyfills';

import startServiceWorker from 'services/serviceWorker';
import { runWithAuth } from 'services/auth';

import './style/main.scss';

const runApp = async () => {
  const loadAppPromise = import('app');

  await bootstrapPolyfills();

  const isLoggedIn = await runWithAuth();

  if (isLoggedIn) {
    const { startApp } = await loadAppPromise;

    await Promise.all([
      startServiceWorker(),
      startApp(),
    ]);
  }
};

runApp()
// FIXME handle app start error
// eslint-disable-next-line no-console
  .catch(console.error);
