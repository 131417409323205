export const ACTIONS = {

  GET_SECURITY_DATA: 'SECURITY/GET_SECURITY_DATA',
  GET_SECURITY_DATA_SUCCESS: 'SECURITY/GET_SECURITY_DATA_SUCCESS',
  GET_SECURITY_DATA_FAILURE: 'SECURITY/GET_SECURITY_DATA_FAILURE',

  PATCH_SECURITY_DATA: 'SECURITY/PATCH_SECURITY_DATA',
  PATCH_SECURITY_DATA_SUCCESS: 'SECURITY/PATCH_SECURITY_DATA_SUCCESS',
  PATCH_SECURITY_DATA_FAILURE: 'SECURITY/PATCH_SECURITY_DATA_FAILURE',

};
