import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { ACTIONS } from './constants';

export default combineReducers({
  map: combineReducers({
    macroActivities: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.GET_MACRO_ACTIVITIES_SUCCESS:
          return {
            ...state,
            ...keyBy(action.macroActivities, 'id'),
          };

        case ACTIONS.GET_MACRO_ACTIVITY_SUCCESS:
          return {
            ...state,
            [action.macroActivity.id]: action.macroActivity,
          };

        default:
          return state;
      }
    },

    activities: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.GET_ACTIVITIES_SUCCESS:
        case ACTIONS.GET_MACRO_ACTIVITIES_SUCCESS:
          return {
            ...state,
            ...keyBy(action.activities, 'id'),
          };

        case ACTIONS.GET_ACTIVITY_SUCCESS:
          return {
            ...state,
            [action.activity.id]: action.activity,
          };

        default:
          return state;
      }
    },

    atomicActivities: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.GET_ATOMIC_ACTIVITIES_SUCCESS:
        case ACTIONS.GET_MACRO_ACTIVITIES_SUCCESS:
          return {
            ...state,
            ...keyBy(action.atomicActivities, 'id'),
          };

        case ACTIONS.GET_ATOMIC_ACTIVITY_SUCCESS:
          return {
            ...state,
            [action.atomicActivity.id]: action.atomicActivity,
          };

        default:
          return state;
      }
    },
  }),

  isLoading: combineReducers({
    macroActivities: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_MACRO_ACTIVITIES:
        case ACTIONS.GET_MACRO_ACTIVITY:
          return state + 1;
        case ACTIONS.GET_MACRO_ACTIVITIES_SUCCESS:
        case ACTIONS.GET_MACRO_ACTIVITY_SUCCESS:
        case ACTIONS.GET_MACRO_ACTIVITIES_FAILURE:
        case ACTIONS.GET_MACRO_ACTIVITY_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    activities: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_ACTIVITIES:
        case ACTIONS.GET_ACTIVITY:
          return state + 1;
        case ACTIONS.GET_ACTIVITIES_SUCCESS:
        case ACTIONS.GET_ACTIVITY_SUCCESS:
        case ACTIONS.GET_ACTIVITIES_FAILURE:
        case ACTIONS.GET_ACTIVITY_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    atomicActivities: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_ATOMIC_ACTIVITIES:
        case ACTIONS.GET_ATOMIC_ACTIVITY:
          return state + 1;
        case ACTIONS.GET_ATOMIC_ACTIVITIES_SUCCESS:
        case ACTIONS.GET_ATOMIC_ACTIVITY_SUCCESS:
        case ACTIONS.GET_ATOMIC_ACTIVITIES_FAILURE:
        case ACTIONS.GET_ATOMIC_ACTIVITY_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
