import { set, del } from 'object-path-immutable';
import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCT_DEPLOYMENTS_SUCCESS:
        return {
          ...state,
          [action.productId]: action.deployments.reduce(
            (acc, deployment) => set(acc, `${deployment.version}.${deployment.id}`, deployment),
            {},
          ),
        };
      case ACTIONS.POST_PRODUCT_DEPLOYMENT_SUCCESS:
      case ACTIONS.PATCH_PRODUCT_DEPLOYMENT_SUCCESS:
        return set(
          state,
          `${action.productId}.${action.deployment.version}.${action.deployment.id}`,
          action.deployment,
        );
      case ACTIONS.DELETE_PRODUCT_DEPLOYMENT_SUCCESS:
        return del(
          state,
          `${action.productId}.${action.versionId}.${action.deploymentId}`,
        );
      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getDeployments: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_PRODUCT_DEPLOYMENTS:
          return state + 1;
        case ACTIONS.GET_PRODUCT_DEPLOYMENTS_SUCCESS:
        case ACTIONS.GET_PRODUCT_DEPLOYMENTS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    postDeployment: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.POST_PRODUCT_DEPLOYMENT:
          return state + 1;
        case ACTIONS.POST_PRODUCT_DEPLOYMENT_SUCCESS:
        case ACTIONS.POST_PRODUCT_DEPLOYMENT_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    patchDeployment: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.PATCH_PRODUCT_DEPLOYMENT:
          return state + 1;
        case ACTIONS.PATCH_PRODUCT_DEPLOYMENT_SUCCESS:
        case ACTIONS.PATCH_PRODUCT_DEPLOYMENT_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    deleteDeployment: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.DELETE_PRODUCT_DEPLOYMENT:
          return state + 1;
        case ACTIONS.DELETE_PRODUCT_DEPLOYMENT_SUCCESS:
        case ACTIONS.DELETE_PRODUCT_DEPLOYMENT_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
