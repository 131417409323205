import * as UsersService from 'services/users';

export * from './notifications.repository';

export const {
  postSubscription,
  getNotifications,
  getUnreadNotificationsCount,
  setNotificationRead,
  setNotificationsAsRead,
  removeNotification,
  removeNotifications,
} = UsersService;
