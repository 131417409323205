export const ACTIONS = {
  GET_ALL_REVIEWS_COUNT: 'REVIEWS/GET_ALL_REVIEWS_COUNT',
  GET_ALL_REVIEWS_COUNT_SUCCESS: 'REVIEWS/GET_ALL_REVIEWS_COUNT_SUCCESS',
  GET_ALL_REVIEWS_COUNT_FAILURE: 'REVIEWS/GET_ALL_REVIEWS_COUNT_FAILURE',

  GET_PRODUCT_REVIEWS: 'REVIEWS/GET_PRODUCT_REVIEWS',
  GET_PRODUCT_REVIEWS_SUCCESS: 'REVIEWS/GET_PRODUCT_REVIEWS_SUCCESS',
  GET_PRODUCT_REVIEWS_FAILURE: 'REVIEWS/GET_PRODUCT_REVIEWS_FAILURE',

  GET_PRODUCT_REVIEW: 'REVIEWS/GET_PRODUCT_REVIEW',
  GET_PRODUCT_REVIEW_SUCCESS: 'REVIEWS/GET_PRODUCT_REVIEW_SUCCESS',
  GET_PRODUCT_REVIEW_FAILURE: 'REVIEWS/GET_PRODUCT_REVIEW_FAILURE',

  CREATE_PRODUCT_REVIEW: 'REVIEWS/CREATE_PRODUCT_REVIEW',
  CREATE_PRODUCT_REVIEW_SUCCESS: 'REVIEWS/CREATE_PRODUCT_REVIEW_SUCCESS',
  CREATE_PRODUCT_REVIEW_FAILURE: 'REVIEWS/CREATE_PRODUCT_REVIEW_FAILURE',

  VOTE_PRODUCT_REVIEW: 'REVIEWS/VOTE_PRODUCT_REVIEW',
  VOTE_PRODUCT_REVIEW_SUCCESS: 'REVIEWS/VOTE_PRODUCT_REVIEW_SUCCESS',
  VOTE_PRODUCT_REVIEW_FAILURE: 'REVIEWS/VOTE_PRODUCT_REVIEW_FAILURE',

  PATCH_PRODUCT_REVIEW: 'REVIEWS/PATCH_PRODUCT_REVIEW',
  PATCH_PRODUCT_REVIEW_SUCCESS: 'REVIEWS/PATCH_PRODUCT_REVIEW_SUCCESS',
  PATCH_PRODUCT_REVIEW_FAILURE: 'REVIEWS/PATCH_PRODUCT_REVIEW_FAILURE',

  DELETE_PRODUCT_REVIEW: 'REVIEWS/DELETE_PRODUCT_REVIEW',
  DELETE_PRODUCT_REVIEW_SUCCESS: 'REVIEWS/DELETE_PRODUCT_REVIEW_SUCCESS',
  DELETE_PRODUCT_REVIEW_FAILURE: 'REVIEWS/DELETE_PRODUCT_REVIEW_FAILURE',

  GET_LATEST_REVIEWS: 'REVIEWS/GET_LATEST_REVIEWS',
  GET_LATEST_REVIEWS_SUCCESS: 'REVIEWS/GET_LATEST_REVIEWS_SUCCESS',
  GET_LATEST_REVIEWS_FAILURE: 'REVIEWS/GET_LATEST_REVIEWS_FAILURE',
};
