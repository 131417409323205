import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import memoize from 'memoize-one';

export const featureFactory = () => ({ id: uuid() });
export const teamMemberFactory = () => ({ id: uuid() });
export const businessUnitFactory = () => ({ id: uuid() });
export const screenshotsFactory = () => ({ id: uuid() });
export const moduleFactory = () => ({ id: uuid() });
export const linkFactory = () => ({ id: uuid() });

/** Step manifest. */
export class Manifest {
  /**
   * @param {object} params - Params.
   * @param {object} params.fields - Fields of step.
   */
  constructor({ fields } = {}) {
    this.fields = fields;
  }

  /**
   * @param {object} syncErrors - Sync errors map.
   * @returns {number} Number of sync errors in fields of this manifest.
   */
  getSyncErrorsCount = memoize(syncErrors => Object.values(this.fields).reduce(
    (acc, field) => (acc + field?.getSyncErrorsCount?.(syncErrors)),
    0,
  ));

  /**
   * @param {object} asyncErrors - Async errors map.
   * @returns {number} Number of async errors in fields of this manifest.
   */
  getAsyncErrorsCount = memoize(asyncErrors => Object.values(this.fields).reduce(
    (acc, field) => (acc + field?.getAsyncErrorsCount?.(asyncErrors)),
    0,
  ));

  /**
   * @param {object} syncErrors - Sync errors map.
   * @param {object} asyncErrors - Async errors map.
   * @returns {number} Number of sync and async errors in fields of this manifest.
   */
  getErrorsCount(syncErrors, asyncErrors) {
    return this.getSyncErrorsCount(syncErrors) + this.getAsyncErrorsCount(asyncErrors);
  }

  /**
   * @param {object} syncWarningsCount - Sync warnings map.
   * @returns {number} Number of sync warnings in fields of this manifest.
   */
  getSyncWarningsCount = memoize(syncWarningsCount => Object.values(this.fields).reduce(
    (acc, field) => (acc + field?.getSyncWarningsCount?.(syncWarningsCount)),
    0,
  ));

  /**
   * @param {object} syncWarnings - Sync warnings map.
   * @returns {number} Number of sync and async warnings in fields of this manifest.
   */
  getWarningsCount(syncWarnings) {
    return this.getSyncWarningsCount(syncWarnings);
  }

  /**
   * Set all fields as touched.
   *
   * @param {Function} touch - Touch action.
   * @param {object} allValues - All values from the form.
   */
  touchFields(touch, allValues) {
    const fieldsNames = Object.values(this.fields)
      .flatMap(field => field.getAllFieldsNames?.(allValues))
      .filter(Boolean);

    if (!isEmpty(fieldsNames)) {
      touch(...fieldsNames);
    }
  }
}
