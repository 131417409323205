import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux';
import ReduxThunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { reducer as formReducer } from 'redux-form';

import { history } from 'services/router';

import appReducers from './app/reducers';
import architectureBuildingBlocksReducers from './architectureBuildingBlocks/reducers';
import businessActivitiesReducers from './businessActivities/reducers';
import businessUnitsReducers from './businessUnits/reducers';
import communitiesReducers from './communities/reducers';
import notificationsReducers from './notifications/reducers';
import productsReducers from './products/reducers';
import responsiveReducers from './responsive/reducers';
import reviewsReducers from './reviews/reducers';
import usersReducers from './users/reducers';
import repliesReducers from './replies/reducers';
import productsHistoryReducers from './histories/reducers';
import searchesReducers from './searches/reducers';
import toastsReducers from './toasts/reducers';
import subsetsReducers from './subsets/reducers';
import suggestionsReducers from './suggestions/reducers';
import suggestionRepliesReducers from './suggestions/replies/reducers';
import versionsReducers from './versions/reducers';
import deploymentsReducers from './deployments/reducers';
import snippetConfigsReducers from './snippet/reducers';
import securityReducers from './security/reducers';
import tipsAndNewsReducers from './tipsAndNews/reducers';
import localOrganizationsReducers from './localOrganizations/reducers';

import runNotificationsSagas from './notifications/sagas';
import runProductsSagas from './products/sagas';
import { errorHandler } from './middlewares';

export const reducers = combineReducers({
  app: appReducers,
  architectureBuildingBlocks: architectureBuildingBlocksReducers,
  businessActivities: businessActivitiesReducers,
  businessUnits: businessUnitsReducers,
  communities: communitiesReducers,
  notifications: notificationsReducers,
  products: productsReducers,
  responsive: responsiveReducers,
  reviews: reviewsReducers,
  users: usersReducers,
  replies: repliesReducers,
  productsHistory: productsHistoryReducers,
  searches: searchesReducers,
  toasts: toastsReducers,
  subsets: subsetsReducers,
  suggestions: suggestionsReducers,
  suggestionReplies: suggestionRepliesReducers,
  versions: versionsReducers,
  deployments: deploymentsReducers,
  snippetConfig: snippetConfigsReducers,
  security: securityReducers,
  tipsAndNews: tipsAndNewsReducers,
  localOrganizations: localOrganizationsReducers,

  // Vendors.
  router: connectRouter(history),
  form: formReducer,
});

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  ReduxThunkMiddleware,
  routerMiddleware(history),
  sagaMiddleware,
  errorHandler,
];

const isDev = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test';
const controlLogger = false;

if (isDev && controlLogger) {
  middlewares.push(createLogger({
    collapsed: true,
    diff: true,
    duration: true,
  }));
}

const composedMiddlewares = isDev ?
  composeWithDevTools(
    responsiveStoreEnhancer,
    applyMiddleware(...middlewares),
  )
  : compose(
    responsiveStoreEnhancer,
    applyMiddleware(...middlewares),
  );

const store = createStore(
  reducers,
  composedMiddlewares,
);

runNotificationsSagas(sagaMiddleware);
runProductsSagas(sagaMiddleware);

export default store;
