import { isEmpty, isEqual, pick } from 'lodash';
import {
  take,
  takeLatest,
  select,
  put,
  putResolve,
  delay,
  spawn,
  join,
} from 'redux-saga/effects';
import {
  actionTypes,
  initialize,
  getFormSyncErrors,
  getFormAsyncErrors,
  getFormValues,
  getFormInitialValues,
} from 'redux-form';

import { DRAFT_AUTOSAVE_DEBOUNCE_TIME } from 'config/constants';
import { fromFormToStore, fromStoreToForm } from 'services/products/product-form.mapper';
import { addToast } from 'redux/toasts/actions';

import { selectIsFormLoading } from '../selectors';
import { createProduct, editProduct } from '../actions';
import { ACTIONS } from '../constants';

import messages from './form-autosave.messages';

const requiredFieldsRepository = [
  'community',
  'domain',
  'name',
  'stage',
  'teamMembers',
];

const commonToastOptions = {
  autoDismiss: true,
  pauseOnHover: true,
};

const getToastMessage = (productId, success) => {
  switch (true) {
    case !productId && success:
      return messages.AUTOSAVE_CREATE_SUCCESS;
    case !productId && !success:
      return messages.AUTOSAVE_CREATE_FAILURE;
    case productId && success:
      return messages.AUTOSAVE_SUCCESS;
    default:
      return messages.AUTOSAVE_FAILURE;
  }
};

/**
 * Create or edit the product as draft, and reinit the form.
 *
 * @param {string} formName - Redux-form form name.
 * @param {object} formattedProduct - Product to save (store format).
 * @param {string} [productId] - Id of the product to edit (falsy if product not created yet).
 * @param {object} [formValues] - Form values.
 * @returns {{success: boolean}} Success state of the save action.
 */
function* saveAndReinitForm(formName, formattedProduct, productId, formValues) {
  // Edit or create if necessary.
  const payload = { ...formattedProduct, isDraft: true };
  const { type, product } = yield putResolve(
    productId ?
      editProduct(formName, productId, payload)
      : createProduct(formName, payload),
  );

  const success = [ACTIONS.CREATE_PRODUCT_SUCCESS, ACTIONS.EDIT_PRODUCT_SUCCESS].includes(type);

  if (success) {
    // On success.
    // Reinitalize the form with the updated product, will be used to prevent user navigation.
    yield put(initialize(
      formName,
      fromStoreToForm({
        ...product,
        community: formValues.community ? {
          id: formValues.community.value,
          name: formValues.community.label,
        } : null,
      }),
      {
        keepDirty: true,
        updateUnregisteredFields: true,
      },
    ));
  }

  return { success };
}

/**
 * Autosave on change.
 *
 * @param {object} action - Redux change or submit action.
 */
function* formAutosave(action) {
  const { form: formName } = action.meta;
  const { field } = action.meta;

  // Get the values from the form and remap them to store format.
  const initialFormattedProduct = fromFormToStore(yield select(getFormInitialValues(formName)));
  const formValues = yield select(getFormValues(formName));
  const formattedProduct = fromFormToStore(formValues);
  const { id: productId, isDraft } = formattedProduct;

  if (field === 'validation.rejectedReason' || field === 'validation.rejectedReasonDescription') return;

  if (
    (action.type === actionTypes.CHANGE && productId)
    || ((action.type === actionTypes.BLUR || action.type === actionTypes.CHANGE) && !productId)) {
    // Wait in case other changes are done by the user.
    // This also allows the autosave process to be canceled if the user presses the publish button.
    yield delay(DRAFT_AUTOSAVE_DEBOUNCE_TIME);

    // Abort if any sync errors on required fields.
    const syncErrors = yield select(getFormSyncErrors(formName));
    if (!isEmpty(pick(syncErrors, requiredFieldsRepository))) {
      return;
    }

    // If it is still validating asynchronously, wait for the end of the validation.
    if (yield select(state => state.form[formName].asyncValidating)) {
      yield take([actionTypes.STOP_ASYNC_VALIDATION]);
    }

    // Abort if any async errors on required fields.
    const asyncErrors = yield select(getFormAsyncErrors(formName));
    if (!isEmpty(pick(asyncErrors, requiredFieldsRepository))) {
      return;
    }

    // If the form is still loading, wait for the api to respond.
    // (Aka if the API takes longer than DRAFT_AUTOSAVE_DEBOUNCE_TIME to respond).
    if (yield select(selectIsFormLoading(formName))) {
      const { type: saveActionType } = yield take([
        ACTIONS.CREATE_PRODUCT_SUCCESS,
        ACTIONS.EDIT_PRODUCT_SUCCESS,
        ACTIONS.CREATE_PRODUCT_FAILURE,
        ACTIONS.EDIT_PRODUCT_FAILURE,
      ]);

      // In case of success, wait for the redux-form initialize action.
      // Otherwise the next call to get the form values will not get the product id.
      if ([ACTIONS.CREATE_PRODUCT_SUCCESS, ACTIONS.EDIT_PRODUCT_SUCCESS].includes(saveActionType)) {
        yield take([actionTypes.INITIALIZE]);
      }
    }

    // If the product is not yet saved, or still a draft, save the product as a draft.
    // If no more changes to save, do not save.
    if ((!productId || isDraft) && !isEqual(initialFormattedProduct, formattedProduct)) {
      // Here we use spawn to start the saving process, because once the product starts saving,
      // we need to reinit the form. If we do not do this atomically and CHANGE/SUBMIT is
      // dispatched, the saga is canceled and restarts in an invalid state (the product id is not
      // saved in the form and the next call will also be to createProduct and will fail because
      // the name is already taken).
      const task = yield spawn(
        saveAndReinitForm,
        formName,
        formattedProduct,
        productId,
        formValues,
      );

      // Wait for the process to end to display a toast. If the process is canceled, no toast is
      // displayed but the state is valid.
      const { success } = yield join(task);

      // Display a toast to inform user that his product is saved or updated, or that there was an
      // error.
      yield put(addToast(
        getToastMessage(productId, success),
        {
          appearance: success ? 'success' : 'warning',
          ...commonToastOptions,
        },
      ));
    }
  }
}

/**
 * Launch autosave on each change event.
 * Stop previous process if new event received before it ended.
 */
export default function* formAutosaveSaga() {
  yield takeLatest(
    [
      actionTypes.BLUR,
      actionTypes.CHANGE,
      actionTypes.SUBMIT,
    ],
    formAutosave,
  );
}
