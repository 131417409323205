import * as NotificationService from 'services/notifications';

import store from 'redux/store';
import { newNotification } from 'redux/notifications/actions';
import { extractScriptName } from 'services/utils';

const SERVICE_WORKER_NAME = 'service-worker.tangram.js';

/**
 * Convert the token into an Uint8Array.
 *
 * @see https://github.com/GoogleChromeLabs/web-push-codelab/blob/master/app/scripts/main.js
 * @param {string} base64String - Input string.
 * @returns {Uint8Array} - Converted array.
 */
function urlBase64ToUint8Array(base64String) {
  // eslint-disable-next-line no-mixed-operators
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 * Unregister the obsolete registrations.
 *
 * @async
 * @returns {Promise<boolean[]>} - Promises.
 */
const unregisterObsoleteRegistrations = async () => {
  const registrations = await navigator.serviceWorker.getRegistrations();
  return Promise.all(
    registrations.map((registration) => {
      if (extractScriptName(registration?.active?.scriptUrl) !== SERVICE_WORKER_NAME) {
        return registration.unregister();
      }
      return Promise.resolve(true);
    }),
  );
};

/**
 * Returns SW subscription if it exists, or call the pushManager
 * to generate a new one.
 *
 * @async
 * @param {ServiceWorkerRegistration} registration - The registration.
 * @returns {PushSubscription} - The subscription.
 */
async function getSubscription(registration) {
  // Try to get the subscription from the sw.
  const subscription = await registration.pushManager.getSubscription();

  if (subscription) {
    return subscription;
  }

  // No subscription yet, let's generate a new one.
  const response = await NotificationService.getInfos();

  const convertedVapidKey = urlBase64ToUint8Array(response.publicKey);
  const newSubscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: convertedVapidKey,
  });

  // eslint-disable-next-line no-console
  console.log('New subscription registered.');
  return newSubscription;
}

export default async () => {
  if ('serviceWorker' in navigator) {
    // Unregister obsolete registrations
    await unregisterObsoleteRegistrations();

    // Register serviceWorker.
    const registration = await navigator.serviceWorker.register(`/${SERVICE_WORKER_NAME}`);

    // Force an update in case a new version was delivered.
    // It will download it only if sw.js is different.
    await registration.update();

    // Wait until the sw is ready.
    const readyRegistration = await navigator.serviceWorker.ready;

    // Retrieves the subscription.
    const subscription = await getSubscription(readyRegistration);

    // Post the subscription endpoint to the API.
    await NotificationService.postSubscription(subscription);

    // Listen for messages from the service worker.
    navigator.serviceWorker.addEventListener('message', (event) => {
      switch (event.data.type) {
        // Dispatch a redux action when receiving a new notification from the service worker.
        case 'SW/NEW_NOTIFICATION':
          return store.dispatch(newNotification());
        default:
          return null;
      }
    });
  }
};
