import {
  take,
  race,
  put,
  delay,
} from 'redux-saga/effects';

import { FOLLOW_PRODUCT_BANNER_TIMEOUT } from 'config/constants';
import { ACTIONS as USER_ACTIONS } from 'redux/users/constants';

import { setDisplayBannerFollowProduct } from '../actions';

/**
 * When requesting to follow a product, hide the follow product banner after a delay.
 * If the user requests to follow a different product, reset the timeout.
 */
export default function* hideFollowProductBannerSaga() {
  let timedOut = true;
  while (true) {
    if (timedOut) {
      yield take(USER_ACTIONS.FOLLOW_PRODUCT);
    }

    ({ timedOut } = yield race({
      timedOut: delay(FOLLOW_PRODUCT_BANNER_TIMEOUT),
      newFollow: take(USER_ACTIONS.FOLLOW_PRODUCT),
    }));

    if (timedOut) {
      yield put(setDisplayBannerFollowProduct(false));
    }
  }
}
