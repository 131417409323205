import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  data: (state = [], action) => {
    switch (action.type) {
      case ACTIONS.GET_TIPS_AND_NEWS_SUCCESS:
        return action.result;

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getTipsAndNews: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_TIPS_AND_NEWS:
          return state + 1;
        case ACTIONS.GET_TIPS_AND_NEWS_SUCCESS:
        case ACTIONS.GET_TIPS_AND_NEWS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
