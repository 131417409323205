export const ACTIONS = {
  GET_ALL_SUGGESTIONS_COUNT: 'SUGGESTIONS/GET_ALL_SUGGESTIONS_COUNT',
  GET_ALL_SUGGESTIONS_COUNT_SUCCESS: 'SUGGESTIONS/GET_ALL_SUGGESTIONS_COUNT_SUCCESS',
  GET_ALL_SUGGESTIONS_COUNT_FAILURE: 'SUGGESTIONS/GET_ALL_SUGGESTIONS_COUNT_FAILURE',

  GET_PRODUCT_SUGGESTIONS: 'SUGGESTIONS/GET_PRODUCT_SUGGESTIONS',
  GET_PRODUCT_SUGGESTIONS_SUCCESS: 'SUGGESTIONS/GET_PRODUCT_SUGGESTIONS_SUCCESS',
  GET_PRODUCT_SUGGESTIONS_FAILURE: 'SUGGESTIONS/GET_PRODUCT_SUGGESTIONS_FAILURE',

  GET_PRODUCT_SUGGESTION: 'SUGGESTIONS/GET_PRODUCT_SUGGESTION',
  GET_PRODUCT_SUGGESTION_SUCCESS: 'SUGGESTIONS/GET_PRODUCT_SUGGESTION_SUCCESS',
  GET_PRODUCT_SUGGESTION_FAILURE: 'SUGGESTIONS/GET_PRODUCT_SUGGESTION_FAILURE',

  CREATE_PRODUCT_SUGGESTION: 'SUGGESTIONS/CREATE_PRODUCT_SUGGESTION',
  CREATE_PRODUCT_SUGGESTION_SUCCESS: 'SUGGESTIONS/CREATE_PRODUCT_SUGGESTION_SUCCESS',
  CREATE_PRODUCT_SUGGESTION_FAILURE: 'SUGGESTIONS/CREATE_PRODUCT_SUGGESTION_FAILURE',

  VOTE_PRODUCT_SUGGESTION: 'SUGGESTIONS/VOTE_PRODUCT_SUGGESTION',
  VOTE_PRODUCT_SUGGESTION_SUCCESS: 'SUGGESTIONS/VOTE_PRODUCT_SUGGESTION_SUCCESS',
  VOTE_PRODUCT_SUGGESTION_FAILURE: 'SUGGESTIONS/VOTE_PRODUCT_SUGGESTION_FAILURE',

  PATCH_PRODUCT_SUGGESTION: 'SUGGESTIONS/PATCH_PRODUCT_SUGGESTION',
  PATCH_PRODUCT_SUGGESTION_SUCCESS: 'SUGGESTIONS/PATCH_PRODUCT_SUGGESTION_SUCCESS',
  PATCH_PRODUCT_SUGGESTION_FAILURE: 'SUGGESTIONS/PATCH_PRODUCT_SUGGESTION_FAILURE',

  DELETE_PRODUCT_SUGGESTION: 'SUGGESTIONS/DELETE_PRODUCT_SUGGESTION',
  DELETE_PRODUCT_SUGGESTION_SUCCESS: 'SUGGESTIONS/DELETE_PRODUCT_SUGGESTION_SUCCESS',
  DELETE_PRODUCT_SUGGESTION_FAILURE: 'SUGGESTIONS/DELETE_PRODUCT_SUGGESTION_FAILURE',
};
