import { keyBy, omit } from 'lodash';
import { combineReducers } from 'redux';
import { ACTIONS as SEARCH_ACTIONS } from 'redux/searches/constants';

import { ACTIONS as PRODUCT_ACTIONS } from '../products/constants';
import { ACTIONS } from './constants';

export default combineReducers({
  current: (state = {}, {
    type,
    user,
    preferences,
    cachedFilters,
  }) => {
    switch (type) {
      case ACTIONS.GET_CURRENT_USER_SUCCESS:
        return user;
      case ACTIONS.SET_CURRENT_USER_PREFERENCES_SUCCESS:
      case ACTIONS.GET_CURRENT_USER_PREFERENCES_SUCCESS:
        return {
          ...state,
          preferences,
        };

      case SEARCH_ACTIONS.SEARCH_BY_TITLE:
        return {
          ...state,
          cachedSearchFilters: {},
        };

      case SEARCH_ACTIONS.SET_CACHED_FILTERS:
        return {
          ...state,
          cachedSearchFilters: cachedFilters,
        };

      default:
        return state;
    }
  },

  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_USERS_SUCCESS:
        return {
          ...state,
          ...keyBy(action.users, 'id'),
        };

      default:
        return state;
    }
  },

  externalUsersMap: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_USERS_BY_EXTERNAL_IDS_SUCCESS:
        return {
          ...state,
          ...keyBy(action.users, 'externalId'),
        };
      case ACTIONS.GET_USER_WORKPLACE_ID_SUCCESS:
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            workplaceId: action.workplaceId,
          },
        };

      default:
        return state;
    }
  },

  idsLoading: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_USERS:
        return action.ids.reduce(
          (nextState, id) => {
            nextState[id] = true;
            return nextState;
          },
          { ...state },
        );

      case ACTIONS.GET_USERS_SUCCESS:
      case ACTIONS.GET_USERS_FAILURE:
        return omit(state, action.ids);
      default:
        return state;
    }
  },

  externalIdsLoading: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_USERS_BY_EXTERNAL_IDS:
        return action.ids.reduce(
          (nextState, id) => {
            nextState[id] = true;
            return nextState;
          },
          { ...state },
        );

      case ACTIONS.GET_USERS_BY_EXTERNAL_IDS_SUCCESS:
      case ACTIONS.GET_USERS_BY_EXTERNAL_IDS_FAILURE:
        return omit(state, action.ids);
      default:
        return state;
    }
  },

  rolesMap: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_ROLES_ON_TOP_SUCCESS:
        return {
          ...state,
          ...keyBy(action.roles, 'id'),
        };
      default:
        return state;
    }
  },

  managerRolesMap: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_MANAGER_ROLES_SUCCESS:
        return {
          ...state,
          ...keyBy(action.roles, 'id'),
        };
      default:
        return state;
    }
  },

  myCreatedProductsMap: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_MY_CREATED_PRODUCTS_SUCCESS:
        return {
          // ...state,
          ...keyBy(action.myProducts, 'id'),
        };

      case PRODUCT_ACTIONS.DELETE_PRODUCT_SUCCESS:
        return omit(state, [action.productId]);

      default:
        return state;
    }
  },

  productsToValidateMap: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCTS_TO_VALIDATE_SUCCESS:
        if (action.count < 1) return {};

        return {
          // ...state,
          ...keyBy(action.products, 'id'),
        };
      default:
        return state;
    }
  },

  totalCreatedProductCount: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.GET_MY_CREATED_PRODUCTS_SUCCESS:
        return action.count;

      case PRODUCT_ACTIONS.DELETE_PRODUCT_SUCCESS:
        return state - 1;

      default:
        return state;
    }
  },

  totalProductsToValidateCount: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCTS_TO_VALIDATE_SUCCESS:
        return action.count;

      default:
        return state;
    }
  },

  myFollowedProductsMap: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_MY_FOLLOWED_PRODUCTS_SUCCESS:
        return {
          ...state,
          ...keyBy(action.myProducts, 'id'),
        };
      case ACTIONS.UPDATE_FOLLOWED_PRODUCT:
        return {
          ...state,
          [action.productId]: {
            ...state[action.productId],
            followed: !state[action.productId].followed,
          },
        };

      case ACTIONS.DELETE_PRODUCT_SUCCESS:
        return omit(state, [action.productId]);

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getCurrentUser: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_CURRENT_USER:
          return state + 1;
        case ACTIONS.GET_CURRENT_USER_SUCCESS:
        case ACTIONS.GET_CURRENT_USER_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getUserWorkplaceId: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.GET_USER_WORKPLACE_ID:
          return {
            ...state,
            [action.id]: (state[action.id] || 0) + 1,
          };
        case ACTIONS.GET_USER_WORKPLACE_ID_FAILURE:
        case ACTIONS.GET_USER_WORKPLACE_ID_SUCCESS:
          return {
            ...state,
            [action.id]: (state[action.id] || 0) - 1,
          };
        default:
          return state;
      }
    },

    getUsers: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_USERS:
          return state + 1;
        case ACTIONS.GET_USERS_SUCCESS:
        case ACTIONS.GET_USERS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    preferences: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_CURRENT_USER_PREFERENCES:
        case ACTIONS.SET_CURRENT_USER_PREFERENCES:
          return state + 1;
        case ACTIONS.GET_CURRENT_USER_PREFERENCES_SUCCESS:
        case ACTIONS.SET_CURRENT_USER_PREFERENCES_SUCCESS:
        case ACTIONS.GET_CURRENT_USER_PREFERENCES_FAILURE:
        case ACTIONS.SET_CURRENT_USER_PREFERENCES_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    roles: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_ROLES_ON_TOP:
          return state + 1;
        case ACTIONS.GET_ROLES_ON_TOP_SUCCESS:
        case ACTIONS.GET_ROLES_ON_TOP_FAILURE:
          return state - 1;
        default:
          return state;
      }
    }, /*

    managerRoles: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_MANAGER_ROLES:
          return state + 1;
        case ACTIONS.GET_MANAGER_ROLES_SUCCESS:
        case ACTIONS.GET_MANAGER_ROLES_FAILURE:
          return state - 1;
        default:
          return state;
      }
    }, */
  }),
});
