export const ACTIONS = {
  GET_PRODUCT_DEPLOYMENTS: 'DEPLOYMENT/GET_PRODUCT_DEPLOYMENTS',
  GET_PRODUCT_DEPLOYMENTS_SUCCESS: 'DEPLOYMENT/GET_PRODUCT_DEPLOYMENTS_SUCCESS',
  GET_PRODUCT_DEPLOYMENTS_FAILURE: 'DEPLOYMENT/GET_PRODUCT_DEPLOYMENTS_FAILURE',

  POST_PRODUCT_DEPLOYMENT: 'DEPLOYMENT/POST_PRODUCT_DEPLOYMENT',
  POST_PRODUCT_DEPLOYMENT_SUCCESS: 'DEPLOYMENT/POST_PRODUCT_DEPLOYMENT_SUCCESS',
  POST_PRODUCT_DEPLOYMENT_FAILURE: 'DEPLOYMENT/POST_PRODUCT_DEPLOYMENT_FAILURE',

  PATCH_PRODUCT_DEPLOYMENT: 'DEPLOYMENT/PATCH_PRODUCT_DEPLOYMENT',
  PATCH_PRODUCT_DEPLOYMENT_SUCCESS: 'DEPLOYMENT/PATCH_PRODUCT_DEPLOYMENT_SUCCESS',
  PATCH_PRODUCT_DEPLOYMENT_FAILURE: 'DEPLOYMENT/PATCH_PRODUCT_DEPLOYMENT_FAILURE',

  DELETE_PRODUCT_DEPLOYMENT: 'DEPLOYMENT/DELETE_PRODUCT_DEPLOYMENT',
  DELETE_PRODUCT_DEPLOYMENT_SUCCESS: 'DEPLOYMENT/DELETE_PRODUCT_DEPLOYMENT_SUCCESS',
  DELETE_PRODUCT_DEPLOYMENT_FAILURE: 'DEPLOYMENT/DELETE_PRODUCT_DEPLOYMENT_FAILURE',
};
