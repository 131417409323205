export const ACTIONS = {
  GET_CURRENT_USER: 'USERS/GET_CURRENT_USER',
  GET_CURRENT_USER_SUCCESS: 'USERS/GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE: 'USERS/GET_CURRENT_USER_FAILURE',

  GET_CURRENT_USER_PREFERENCES: 'USERS/GET_CURRENT_USER_PREFERENCES',
  GET_CURRENT_USER_PREFERENCES_SUCCESS: 'USERS/GET_CURRENT_USER_PREFERENCES_SUCCESS',
  GET_CURRENT_USER_PREFERENCES_FAILURE: 'USERS/GET_CURRENT_USER_PREFERENCES_FAILURE',

  SET_CURRENT_USER_PREFERENCES: 'USERS/SET_CURRENT_USER_PREFERENCES',
  SET_CURRENT_USER_PREFERENCES_SUCCESS: 'USERS/SET_CURRENT_USER_PREFERENCES_SUCCESS',
  SET_CURRENT_USER_PREFERENCES_FAILURE: 'USERS/SET_CURRENT_USER_PREFERENCES_FAILURE',

  GET_USERS: 'USERS/GET_USERS',
  GET_USERS_SUCCESS: 'USERS/GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'USERS/GET_USERS_FAILURE',

  GET_USERS_BY_EXTERNAL_IDS: 'USERS/GET_USERS_BY_EXTERNAL_IDS',
  GET_USERS_BY_EXTERNAL_IDS_SUCCESS: 'USERS/GET_USERS_BY_EXTERNAL_IDS_SUCCESS',
  GET_USERS_BY_EXTERNAL_IDS_FAILURE: 'USERS/GET_USERS_BY_EXTERNAL_IDS_FAILURE',

  GET_USER_WORKPLACE_ID: 'USERS/GET_USER_WORKPLACE_ID',
  GET_USER_WORKPLACE_ID_SUCCESS: 'USERS/GET_USER_WORKPLACE_ID_SUCCESS',
  GET_USER_WORKPLACE_ID_FAILURE: 'USERS/GET_USER_WORKPLACE_ID_FAILURE',

  FOLLOW_PRODUCT: 'USER/FOLLOW_PRODUCT',
  FOLLOW_PRODUCT_SUCCESS: 'USER/FOLLOW_PRODUCT_SUCCESS',
  FOLLOW_PRODUCT_FAILURE: 'USER/FOLLOW_PRODUCT_FAILURE',

  UNFOLLOW_PRODUCT: 'USER/UNFOLLOW_PRODUCT',
  UNFOLLOW_PRODUCT_SUCCESS: 'USER/UNFOLLOW_PRODUCT_SUCCESS',
  UNFOLLOW_PRODUCT_FAILURE: 'USER/UNFOLLOW_PRODUCT_FAILURE',

  GET_ROLES_ON_TOP: 'USERS/GET_ROLES_ON_TOP',
  GET_ROLES_ON_TOP_SUCCESS: 'USERS/GET_ROLES_ON_TOP_SUCCESS',
  GET_ROLES_ON_TOP_FAILURE: 'USERS/GET_ROLES_ON_TOP_FAILURE',

  GET_MANAGER_ROLES: 'USERS/GET_MANAGER_ROLES',
  GET_MANAGER_ROLES_SUCCESS: 'USERS/GET_MANAGER_ROLES_SUCCESS',
  GET_MANAGER_ROLES_FAILURE: 'USERS/GET_MANAGER_ROLES_FAILURE',

  GET_MY_CREATED_PRODUCTS: 'USERS/GET_MY_CREATED_PRODUCTS',
  GET_PRODUCTS_TO_VALIDATE: 'USERS/GET_PRODUCTS_TO_VALIDATE',
  GET_MY_CREATED_PRODUCTS_SUCCESS: 'USERS/GET_MY_CREATED_PRODUCTS_SUCCESS',
  GET_PRODUCTS_TO_VALIDATE_SUCCESS: 'USERS/GET_PRODUCTS_TO_VALIDATE_SUCCESS',
  GET_MY_CREATED_PRODUCTS_FAILURE: 'USERS/GET_MY_CREATED_PRODUCTS_FAILURE',
  GET_PRODUCTS_TO_VALIDATE_FAILURE: 'USERS/GET_PRODUCTS_TO_VALIDATE_FAILURE',

  GET_MY_FOLLOWED_PRODUCTS: 'USERS/GET_MY_FOLLOWED_PRODUCTS',
  GET_MY_FOLLOWED_PRODUCTS_SUCCESS: 'USERS/GET_MY_FOLLOWED_PRODUCTS_SUCCESS',
  GET_MY_FOLLOWED_PRODUCTS_FAILURE: 'USERS/GET_MY_FOLLOWED_PRODUCTS_FAILURE',

  UPDATE_FOLLOWED_PRODUCT: 'USERS/UPDATE_FOLLOWED_PRODUCT',
};
