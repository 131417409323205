export const ACTIONS = {
  GET_COMMUNITIES: 'COMMUNITIES/GET_COMMUNITIES',
  GET_COMMUNITIES_SUCCESS: 'COMMUNITIES/GET_COMMUNITIES_SUCCESS',
  GET_COMMUNITIES_FAILURE: 'COMMUNITIES/GET_COMMUNITIES_FAILURE',

  GET_COMMUNITY_DOMAINS: 'COMMUNITIES/GET_COMMUNITY_DOMAINS',
  GET_COMMUNITY_DOMAINS_SUCCESS: 'COMMUNITIES/GET_COMMUNITY_DOMAINS_SUCCESS',
  GET_COMMUNITY_DOMAINS_FAILURE: 'COMMUNITIES/GET_COMMUNITY_DOMAINS_FAILURE',

  GET_DOMAIN: 'COMMUNITIES/GET_DOMAIN',
  GET_DOMAIN_SUCCESS: 'COMMUNITIES/GET_DOMAIN_SUCCESS',
  GET_DOMAIN_FAILURE: 'COMMUNITIES/GET_DOMAIN_FAILURE',
};
