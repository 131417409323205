import { uniqueId } from 'lodash';

import { ACTIONS } from './constants';

export const addToast = (
  content,
  options,
  { isIntlMsg = true } = {},
  appToastId = uniqueId('toast'),
) => ({
  type: ACTIONS.ADD_TOAST,
  toast: { content, options },
  meta: { isIntlMsg },
  appToastId,
});

export const ackAddToast = appToastId => ({
  type: ACTIONS.ACK_ADD_TOAST,
  appToastId,
});
