import { noop } from 'lodash';
import agent from 'services/http';

const e = encodeURIComponent;

/**
 * POST /api/uploads.
 * Post a files collection.
 *
 * @async
 * @param {string} businessObject - Name of the linked business object.
 * @param {string} field - Name of the field.
 * @param {Array<object>} files - Files collection.
 * @returns {Array<object>} - Id and url of the created files.
 */
export const postFiles = async (businessObject, field, files) => {
  const req = agent.post(`/api/uploads?businessObject=${e(businessObject)}&field=${e(field)}`);
  files.forEach(file => req.attach('file', file));

  const { body } = await req;
  return body;
};

/**
 * POST /api/uploads.
 * Post a single file.
 *
 * @async
 * @param {string} businessObject - Name of the linked business object.
 * @param {string} field - Name of the field.
 * @param {object} file - File to upload.
 * @param {Function} onProgressChange - Callback called when upload progress changes.
 * @returns {object} - Id and url of the created file.
 */
export const postFile = async (businessObject, field, file, onProgressChange = noop) => {
  const { body } = await agent
    .post(`/api/uploads?businessObject=${e(businessObject)}&field=${e(field)}`)
    .on('progress', ({ percent }) => onProgressChange(percent))
    .attach('file', file);

  return body;
};
