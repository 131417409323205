export const DEFAULT_LANGUAGE = 'en';

export const LANGUAGES = {
  en: {
    key: 'en',
    label: 'English',
  },
  fr: {
    key: 'fr',
    label: 'Français',
  },
  es: {
    key: 'es',
    label: 'Español',
  },
  pt: {
    key: 'pt',
    label: 'Português',
  },
  zh: {
    key: 'zh',
    label: '中国',
  },
  pl: {
    key: 'pl',
    label: 'Polski',
  },
  el: {
    key: 'el',
    label: 'ελληνικά',
  },
  ro: {
    key: 'ro',
    label: 'Românesc',
  },
  ru: {
    key: 'ru',
    label: 'Pусский',
  },
  uk: {
    key: 'uk',
    label: 'український',
  },
  it: {
    key: 'it',
    label: 'Italiano',
  },
};

export const AVAILABLE_LANGUAGES = Object.keys(LANGUAGES);
