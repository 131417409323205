import * as NotificationsService from 'services/notifications';

import { ACTIONS } from './constants';

export const GET_NOTIFICATIONS = query => ({
  type: ACTIONS.GET_NOTIFICATIONS,
  query,
});

export const GET_NOTIFICATIONS_SUCCESS = (notifications, count) => ({
  type: ACTIONS.GET_NOTIFICATIONS_SUCCESS,
  notifications,
  count,
});

export const GET_NOTIFICATIONS_FAILURE = error => ({
  type: ACTIONS.GET_NOTIFICATIONS_FAILURE,
  error,
});

export const getNotifications = query => async (dispatch) => {
  dispatch(GET_NOTIFICATIONS(query));

  try {
    const { notifications, count } = await NotificationsService.getNotifications(query);
    return dispatch(GET_NOTIFICATIONS_SUCCESS(notifications, count));
  } catch (err) {
    return dispatch(GET_NOTIFICATIONS_FAILURE(err));
  }
};

export const GET_UNREAD_NOTIFICATIONS_COUNT = () => ({
  type: ACTIONS.GET_UNREAD_NOTIFICATIONS_COUNT,
});

export const GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS = count => ({
  type: ACTIONS.GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
  count,
});

export const GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE = error => ({
  type: ACTIONS.GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE,
  error,
});

export const getUnreadNotificationsCount = () => async (dispatch) => {
  dispatch(GET_UNREAD_NOTIFICATIONS_COUNT());

  try {
    const { count } = await NotificationsService.getUnreadNotificationsCount();

    return dispatch(GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS(count));
  } catch (err) {
    return dispatch(GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE(err));
  }
};

export const SET_NOTIFICATION_READ = (notificationId, isRead) => ({
  type: ACTIONS.SET_NOTIFICATION_READ,
  notificationId,
  isRead,
});

export const SET_NOTIFICATION_READ_SUCCESS = notification => ({
  type: ACTIONS.SET_NOTIFICATION_READ_SUCCESS,
  notification,
});

export const SET_NOTIFICATION_READ_FAILURE = (notificationId, error) => ({
  type: ACTIONS.SET_NOTIFICATION_READ_FAILURE,
  notificationId,
  error,
});

export const setNotificationRead = (notificationId, isRead) => async (dispatch) => {
  dispatch(SET_NOTIFICATION_READ(notificationId, isRead));

  try {
    const notification = await NotificationsService.setNotificationRead(notificationId, isRead);
    return dispatch(SET_NOTIFICATION_READ_SUCCESS(notification));
  } catch (err) {
    return dispatch(SET_NOTIFICATION_READ_FAILURE(notificationId, err));
  }
};

export const SET_NOTIFICATIONS_AS_READ = notificationIds => ({
  type: ACTIONS.SET_NOTIFICATIONS_AS_READ,
  notificationIds,
});

export const SET_NOTIFICATIONS_AS_READ_SUCCESS = notifications => ({
  type: ACTIONS.SET_NOTIFICATIONS_AS_READ_SUCCESS,
  notifications,
});

export const SET_NOTIFICATIONS_AS_READ_FAILURE = (notificationIds, error) => ({
  type: ACTIONS.SET_NOTIFICATIONS_AS_READ_FAILURE,
  notificationIds,
  error,
});

export const setNotificationsAsRead = notificationIds => async (dispatch) => {
  dispatch(SET_NOTIFICATIONS_AS_READ(notificationIds));

  try {
    const { notifications } = await NotificationsService.setNotificationsAsRead(notificationIds);
    return dispatch(SET_NOTIFICATIONS_AS_READ_SUCCESS(notifications));
  } catch (err) {
    return dispatch(SET_NOTIFICATIONS_AS_READ_FAILURE(notificationIds, err));
  }
};

export const REMOVE_NOTIFICATION = notificationId => ({
  type: ACTIONS.REMOVE_NOTIFICATION,
  notificationId,
});

export const REMOVE_NOTIFICATION_SUCCESS = notificationId => ({
  type: ACTIONS.REMOVE_NOTIFICATION_SUCCESS,
  notificationId,
});

export const REMOVE_NOTIFICATION_FAILURE = (notificationId, error) => ({
  type: ACTIONS.REMOVE_NOTIFICATION_FAILURE,
  notificationId,
  error,
});

export const removeNotification = notificationId => async (dispatch) => {
  dispatch(REMOVE_NOTIFICATION(notificationId));

  try {
    await NotificationsService.removeNotification(notificationId);
    return dispatch(REMOVE_NOTIFICATION_SUCCESS(notificationId));
  } catch (err) {
    return dispatch(REMOVE_NOTIFICATION_FAILURE(notificationId, err));
  }
};

export const REMOVE_NOTIFICATIONS = notificationIds => ({
  type: ACTIONS.REMOVE_NOTIFICATIONS,
  notificationIds,
});

export const REMOVE_NOTIFICATIONS_SUCCESS = notificationIds => ({
  type: ACTIONS.REMOVE_NOTIFICATIONS_SUCCESS,
  notificationIds,
});

export const REMOVE_NOTIFICATIONS_FAILURE = (notificationIds, error) => ({
  type: ACTIONS.REMOVE_NOTIFICATIONS_FAILURE,
  notificationIds,
  error,
});

export const removeNotifications = notificationIds => async (dispatch) => {
  dispatch(REMOVE_NOTIFICATIONS(notificationIds));

  try {
    await NotificationsService.removeNotifications(notificationIds);
    return dispatch(REMOVE_NOTIFICATIONS_SUCCESS(notificationIds));
  } catch (err) {
    return dispatch(REMOVE_NOTIFICATIONS_FAILURE(notificationIds, err));
  }
};

export const newNotification = () => ({
  type: ACTIONS.NEW_NOTIFICATION,
});

export const SET_NOTIFICATION_FILTER_SUCCESS = filter => ({
  type: ACTIONS.SET_NOTIFICATION_FILTER_SUCCESS,
  filter,
});

export const SET_NOTIFICATION_FILTER_FAILURE = error => ({
  type: ACTIONS.SET_NOTIFICATION_FILTER_FAILURE,
  error,
});

export const setNotificationFilter = filter => async (dispatch) => {
  try {
    return dispatch(SET_NOTIFICATION_FILTER_SUCCESS(filter));
  } catch (err) {
    return dispatch(SET_NOTIFICATION_FILTER_FAILURE(err));
  }
};

export const SET_NOTIFICATION_PAGINATION_SUCCESS = limit => ({
  type: ACTIONS.SET_NOTIFICATION_PAGINATION_SUCCESS,
  limit,
});

export const SET_NOTIFICATION_PAGINATION_FAILURE = error => ({
  type: ACTIONS.SET_NOTIFICATION_PAGINATION_FAILURE,
  error,
});

export const setNotificationPagination = limit => async (dispatch) => {
  try {
    return dispatch(SET_NOTIFICATION_PAGINATION_SUCCESS(limit));
  } catch (err) {
    return dispatch(SET_NOTIFICATION_PAGINATION_FAILURE(err));
  }
};
