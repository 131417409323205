import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { ACTIONS } from './constants';
import { ACTIONS as REPLIES_ACTIONS } from '../replies/constants';

export default combineReducers({
  allReviewsCount: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.GET_ALL_REVIEWS_COUNT_SUCCESS:
        return action.count;
      default:
        return state;
    }
  },

  latestReviews: (state = [], action) => {
    switch (action.type) {
      case ACTIONS.GET_LATEST_REVIEWS_SUCCESS:
        return keyBy(action.reviews, 'id');
      case ACTIONS.GET_PRODUCT_REVIEW_SUCCESS:
        return {
          ...state,
          [action.review.id]: action.review,
        };
      default:
        return state;
    }
  },

  countByProduct: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCT_REVIEWS_SUCCESS:
        return {
          ...state,
          [action.productId]: action.count,
        };

      case ACTIONS.CREATE_PRODUCT_REVIEW_SUCCESS:
        return {
          ...state,
          [action.productId]: state[action.productId] + 1,
        };

      case ACTIONS.DELETE_PRODUCT_REVIEW_SUCCESS:
        return {
          ...state,
          [action.productId]: state[action.productId] - 1,
        };

      default:
        return state;
    }
  },

  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCT_REVIEWS_SUCCESS: {
        const newReviews = keyBy(action.reviews, 'id');

        return {
          ...state,
          [action.productId]: action.preserve ? {
            ...state[action.productId],
            ...newReviews,
          } : newReviews,
        };
      }

      case ACTIONS.GET_PRODUCT_REVIEW_SUCCESS:
      case ACTIONS.PATCH_PRODUCT_REVIEW_SUCCESS:
      case ACTIONS.SET_PRODUCT_REVIEW:
        return {
          ...state,
          [action.productId]: {
            ...state[action.productId],
            [action.review.id]: action.review,
          },
        };

      case ACTIONS.CREATE_PRODUCT_REVIEW_SUCCESS:
        return {
          ...state,
          [action.productId]: {
            [action.review.id]: action.review, // Set the new review in first position.
            ...state[action.productId],
          },
        };

      case ACTIONS.VOTE_PRODUCT_REVIEW_SUCCESS:
        return {
          ...state,
          [action.productId]: {
            ...state[action.productId],
            [action.review.id]: {
              ...state[action.productId]?.[action.review.id],
              helpful: action.review.helpful,
            },
          },
        };

      case ACTIONS.DELETE_PRODUCT_REVIEW_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        const { [action.reviewId]: _, ...rest } = state[action.productId];
        return {
          ...state,
          [action.productId]: {
            ...rest,
          },
        };

      case REPLIES_ACTIONS.CREATE_REPLY_SUCCESS: {
        const { productId, reviewId, replyId } = action;
        return replyId ?
          state
          : {
            ...state,
            [productId]: {
              ...state[productId],
              [reviewId]: {
                ...state[productId][reviewId],
                directRepliesCount: (state[productId][reviewId].directRepliesCount || 0) + 1,
              },
            },
          };
      }

      case REPLIES_ACTIONS.DELETE_REPLY_SUCCESS: {
        const { productId, reviewId } = action;
        return {
          ...state,
          [productId]: {
            ...state[productId],
            [reviewId]: {
              ...state[productId][reviewId],
              directRepliesCount: (state[productId][reviewId].directRepliesCount) - 1,
            },
          },
        };
      }

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getAllReviewsCount: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_ALL_REVIEWS_COUNT:
          return state + 1;
        case ACTIONS.GET_ALL_REVIEWS_COUNT_SUCCESS:
        case ACTIONS.GET_ALL_REVIEWS_COUNT_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getProductReviews: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_PRODUCT_REVIEWS:
          return state + 1;
        case ACTIONS.GET_PRODUCT_REVIEWS_SUCCESS:
        case ACTIONS.GET_PRODUCT_REVIEWS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getProductReview: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_PRODUCT_REVIEW:
          return state + 1;
        case ACTIONS.GET_PRODUCT_REVIEW_SUCCESS:
        case ACTIONS.GET_PRODUCT_REVIEW_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    createProductReview: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.CREATE_PRODUCT_REVIEW:
          return state + 1;
        case ACTIONS.CREATE_PRODUCT_REVIEW_SUCCESS:
        case ACTIONS.CREATE_PRODUCT_REVIEW_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    voteProductReview: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.VOTE_PRODUCT_REVIEW:
          return state + 1;
        case ACTIONS.VOTE_PRODUCT_REVIEW_SUCCESS:
        case ACTIONS.VOTE_PRODUCT_REVIEW_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    patchProductReview: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.PATCH_PRODUCT_REVIEW:
          return state + 1;
        case ACTIONS.PATCH_PRODUCT_REVIEW_SUCCESS:
        case ACTIONS.PATCH_PRODUCT_REVIEW_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    deleteProductReview: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.DELETE_PRODUCT_REVIEW:
          return state + 1;
        case ACTIONS.DELETE_PRODUCT_REVIEW_SUCCESS:
        case ACTIONS.DELETE_PRODUCT_REVIEW_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
