import { defineMessages } from 'react-intl';

export const PRODUCT_TABS_MESSAGES = defineMessages({
  PRODUCT_TABS_OVERVIEW: { id: 'product.tabs.overview', defaultMessage: 'Overview' },
  PRODUCT_TABS_REVIEWS: { id: 'product.tabs.reviews', defaultMessage: 'Feedbacks' },
  PRODUCT_TABS_SUGGESTIONS: { id: 'product.tabs.suggestions', defaultMessage: 'Suggestions' },
});

export const COMPONENT_TABS_MESSAGES = defineMessages({
  COMPONENT_TABS_OVERVIEW: { id: 'component.tabs.overview', defaultMessage: 'Overview' },
  COMPONENT_TABS_REVIEWS: { id: 'component.tabs.reviews', defaultMessage: 'Feedbacks' },
  COMPONENT_TABS_SUGGESTIONS: { id: 'component.tabs.suggestions', defaultMessage: 'Suggestions' },
  COMPONENT_TABS_ADDITIONAL_INFORMATION: { id: 'component.tabs.additionalInformation', defaultMessage: 'Additional Information' },
  COMPONENT_TABS_VERSIONS: { id: 'component.tabs.versions', defaultMessage: 'Versions' },
});

export const NAVIGATION_SHORTCUTS_MESSAGES = defineMessages({
  ALL_PRODUCTS: { id: 'navigation.shortcuts.allProducts', defaultMessage: 'All Products' },
  PRODUCTS_PERSON: { id: 'navigation.shortcuts.productsPerson', defaultMessage: 'Products of person' },
  COMMUNITIES: { id: 'navigation.shortcuts.communities', defaultMessage: 'Platforms' },
  BUSINESS_ACTIVITIES: { id: 'navigation.shortcuts.businessActivities', defaultMessage: 'Business Activities' },
  BUSINESS_UNITS: { id: 'navigation.shortcuts.businessUnits', defaultMessage: 'Business Units' },
  ARCHITECTURE_BUILDING_BLOCKS: { id: 'navigation.shortcuts.abbs', defaultMessage: 'Architecture Building Blocks' },
  SUBSETS: { id: 'navigation.shortcuts.subsets', defaultMessage: 'Subsets' },
});

export const NAVIGATION_SHORTCUTS_COUNT_MESSAGES = defineMessages({
  ALL_PRODUCTS: { id: 'navigation.shortcuts.allProducts.count', defaultMessage: '{count} products' },
  COMMUNITIES: { id: 'navigation.shortcuts.communities.count', defaultMessage: '{count} platforms' },
  BUSINESS_ACTIVITIES: { id: 'navigation.shortcuts.businessActivities.count', defaultMessage: '{count} business activities' },
  BUSINESS_UNITS: { id: 'navigation.shortcuts.businessUnits.count', defaultMessage: '{count} business units' },
});

export const PREFERENCES_TABS_MESSAGES = defineMessages({
  PREFERENCES_TABS_FOLLOWED: { id: 'preferences.tabs.followed', defaultMessage: 'Followed' },
  PREFERENCES_TABS_CREATED: { id: 'preferences.tabs.created', defaultMessage: 'My products & components' },
  PREFERENCES_TABS_VALIDATE: { id: 'preferences.tabs.validate', defaultMessage: 'Validate products & components' },
  PREFERENCES_TABS_NOTIFICATIONS: { id: 'preferences.tabs.notifications', defaultMessage: 'Notifications' },
  PREFERENCES_TABS_HISTORY: { id: 'preferences.tabs.history', defaultMessage: 'History' },
});

export const SETTINGS_TABS_MESSAGES = defineMessages({
  SETTINGS_TABS_LANGUAGE: { id: 'settings.tabs.language', defaultMessage: 'Language settings' },
  SETTINGS_TABS_NOTIFICATIONS: { id: 'settings.tabs.notifications', defaultMessage: 'Notification settings' },
});

export const FEEDBACK_MESSAGES = defineMessages({
  THANK_YOU: { id: 'feedback.success.thankYou', defaultMessage: 'Thank you!' },
  UH_OH: { id: 'feedback.failure.uhoh', defaultMessage: 'Uh oh...' },
  SOMETHING_WENT_WRONG: {
    id: 'feedback.failure.somethingWentWrong',
    defaultMessage: 'Something went wrong. Try again by clicking the button below.',
  },
  TRY_AGAIN: { id: 'feedback.failure.tryAgain', defaultMessage: 'Try again' },
});
