import { keyBy, omit } from 'lodash';
import { combineReducers } from 'redux';

import { ACTIONS } from 'redux/businessUnits/constants';

export default combineReducers({
  count: (state = null, action) => {
    switch (action.type) {
      case ACTIONS.GET_BUSINESS_UNITS_SUCCESS:
        return action.count;
      default:
        return state;
    }
  },

  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_BUSINESS_UNITS_SUCCESS:
        return {
          ...state,
          ...keyBy(action.businessUnits, 'id'),
        };

      default:
        return state;
    }
  },

  idsLoading: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_BUSINESS_UNITS:
        return action.ids.reduce(
          (nextState, id) => {
            nextState[id] = true;
            return nextState;
          },
          { ...state },
        );

      case ACTIONS.GET_BUSINESS_UNITS_SUCCESS:
      case ACTIONS.GET_BUSINESS_UNITS_FAILURE:
        return omit(state, action.ids);
      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getBusinessUnits: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_BUSINESS_UNITS:
          return state + 1;
        case ACTIONS.GET_BUSINESS_UNITS_SUCCESS:
        case ACTIONS.GET_BUSINESS_UNITS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
