import * as ProductsService from 'services/products';

import {
  LATEST_PRODUCTS_ITEMS,
  PRODUCT_SORTING,
} from 'config/constants';
import { ACTIONS } from './constants';

export const GET_PRODUCTS = ({ preserve, ...filters }) => ({
  type: ACTIONS.GET_PRODUCTS,
  filters,
  preserve,
});

export const GET_PRODUCTS_SUCCESS = (products, count, preserve) => ({
  type: ACTIONS.GET_PRODUCTS_SUCCESS,
  products,
  count,
  preserve,
});

export const GET_PRODUCTS_FAILURE = error => ({
  type: ACTIONS.GET_PRODUCTS_FAILURE,
  error,
});

export const GET_PRODUCTS_OR_COMPONENTS = ({ preserve, ...filters }) => ({
  type: ACTIONS.GET_PRODUCTS_OR_COMPONENTS,
  filters,
});

export const GET_PRODUCTS_OR_COMPONENTS_SUCCESS = (products, count) => ({
  type: ACTIONS.GET_PRODUCTS_OR_COMPONENTS_SUCCESS,
  products,
  count,
});

export const GET_PRODUCTS_OR_COMPONENTS_FAILURE = error => ({
  type: ACTIONS.GET_PRODUCTS_OR_COMPONENTS_FAILURE,
  error,
});

export const getProducts = (filters, setInCache = false) => async (dispatch) => {
  dispatch(GET_PRODUCTS(filters));

  try {
    const { preserve, ...otherFilters } = filters;

    const { products, count } = await ProductsService.getProducts({
      ...otherFilters,
      isDraft: false,
    }, setInCache);

    return dispatch(GET_PRODUCTS_SUCCESS(products, count, preserve));
  } catch (err) {
    return dispatch(GET_PRODUCTS_FAILURE(err));
  }
};

export const getProductsOrComponents = ids => async (dispatch) => {
  dispatch(GET_PRODUCTS_OR_COMPONENTS(ids));

  try {
    const { products, count } = await ProductsService.getProductsOrComponentsByIds(ids);

    return dispatch(GET_PRODUCTS_OR_COMPONENTS_SUCCESS(products, count));
  } catch (err) {
    return dispatch(GET_PRODUCTS_OR_COMPONENTS_FAILURE(err));
  }
};

export const GET_PRODUCT = (id, lang) => ({
  type: ACTIONS.GET_PRODUCT,
  id,
  query: {
    lang,
  },
});

export const GET_PRODUCT_SUCCESS = (product, lang) => ({
  type: ACTIONS.GET_PRODUCT_SUCCESS,
  product,
  query: {
    lang,
  },
});

export const GET_PRODUCT_FAILURE = (id, error) => ({
  type: ACTIONS.GET_PRODUCT_FAILURE,
  id,
  error,
});

export const GET_PRODUCT_SERVERS = (id) => ({
  type: ACTIONS.GET_PRODUCT_SERVERS,
  id,
});

export const GET_PRODUCT_SERVERS_SUCCESS = (servers) => ({
  type: ACTIONS.GET_PRODUCT_SERVERS_SUCCESS,
  servers,
});

export const GET_PRODUCT_SERVERS_FAILURE = (id, error) => ({
  type: ACTIONS.GET_PRODUCT_SERVERS_FAILURE,
  id,
  error,
});

export const REMOVE_PRODUCT_FROM_MAP = id => ({
  type: ACTIONS.REMOVE_PRODUCT_FROM_MAP,
  id,
});

export const getProduct = (id, { lang } = {}) => async (dispatch) => {
  dispatch(GET_PRODUCT(id, lang));

  try {
    const product = await ProductsService.getProduct(id, { lang });
    return dispatch(GET_PRODUCT_SUCCESS(product, lang));
  } catch (err) {
    return dispatch(GET_PRODUCT_FAILURE(id, err));
  }
};

export const getProductServers = (id) => async (dispatch) => {
  dispatch(GET_PRODUCT_SERVERS(id));

  try {
    const servers = await ProductsService.getProductServers(id);
    return dispatch(GET_PRODUCT_SERVERS_SUCCESS(servers));
  } catch (err) {
    return dispatch(GET_PRODUCT_SERVERS_FAILURE(id, err));
  }
};

export const getBigProduct = (id, { lang } = {}) => async (dispatch) => {
  dispatch(GET_PRODUCT(id, lang));

  try {
    const product = await ProductsService.getBigProduct(id, { lang });
    return dispatch(GET_PRODUCT_SUCCESS(product, lang));
  } catch (err) {
    return dispatch(GET_PRODUCT_FAILURE(id, err));
  }
};

export const removeProductFromMap
    = productId => dispatch => (productId ? dispatch(REMOVE_PRODUCT_FROM_MAP(productId)) : null);

export const CREATE_PRODUCT = (formName, product) => ({
  type: ACTIONS.CREATE_PRODUCT,
  formName,
  product,
});

export const CREATE_PRODUCT_SUCCESS = (formName, product) => ({
  type: ACTIONS.CREATE_PRODUCT_SUCCESS,
  formName,
  product,
});

export const CREATE_PRODUCT_FAILURE = (formName, error, invalidFields) => ({
  type: ACTIONS.CREATE_PRODUCT_FAILURE,
  formName,
  error,
  invalidFields,
  noRedirect: true,
});

/**
 * @param {string} formName - Redux-form form name.
 * @param {object} product - Product (backend format, not form format).
 * @param {boolean} suppressWarnings - Suppress warnings.
 * @returns {object} Action.
 */
export const createProduct = (
  formName,
  product,
  suppressWarnings = false,
) => async (dispatch) => {
  dispatch(CREATE_PRODUCT(formName, product));

  try {
    const createdProduct = await ProductsService.createProduct(product, suppressWarnings);

    return dispatch(CREATE_PRODUCT_SUCCESS(formName, createdProduct));
  } catch (err) {
    return dispatch(CREATE_PRODUCT_FAILURE(
      formName,
      err,
      err.response.body?.more || [],
    ));
  }
};

export const EDIT_PRODUCT = (formName, product, productId) => ({
  type: ACTIONS.EDIT_PRODUCT,
  formName,
  product,
  productId,
});

export const EDIT_PRODUCT_SUCCESS = (formName, product) => ({
  type: ACTIONS.EDIT_PRODUCT_SUCCESS,
  formName,
  product,
});

export const EDIT_PRODUCT_FAILURE = (formName, error, invalidFields) => ({
  type: ACTIONS.EDIT_PRODUCT_FAILURE,
  formName,
  error,
  invalidFields,
  noRedirect: true,
});

/**
 * @param {string} formName - Redux-form form name.
 * @param {string} productId - Product Id.
 * @param {object} product - Product (backend format, not form format).
 * @param {boolean} suppressWarnings - Suppress warnings.
 * @param {boolean} resendValidation - If true, the validation process
 *                                     will only resend the validation emails.
 * @returns {object} Action.
 */
export const editProduct = (
  formName,
  productId,
  product,
  suppressWarnings = false,
  resendValidation = false,
) => async (dispatch) => {
  dispatch(EDIT_PRODUCT(formName, product, productId));

  try {
    const editedProduct = await ProductsService.editProduct(
      productId,
      product,
      suppressWarnings,
      resendValidation,
    );

    return dispatch(EDIT_PRODUCT_SUCCESS(formName, editedProduct));
  } catch (err) {
    return dispatch(EDIT_PRODUCT_FAILURE(
      formName,
      err,
      err.response.body?.more || [],
    ));
  }
};

export const DELETE_PRODUCT = productId => ({
  type: ACTIONS.DELETE_PRODUCT,
  productId,
});

export const DELETE_PRODUCT_SUCCESS = productId => ({
  type: ACTIONS.DELETE_PRODUCT_SUCCESS,
  productId,
});

export const DELETE_PRODUCT_FAILURE = (productId, error) => ({
  type: ACTIONS.DELETE_PRODUCT_FAILURE,
  productId,
  error,
});

/**
 * @param {string} productId - Product Id.
 * @returns {object} Action.
 */
export const deleteProduct = productId => async (dispatch) => {
  dispatch(DELETE_PRODUCT(productId));

  try {
    await ProductsService.deleteProduct(productId);
    return dispatch(DELETE_PRODUCT_SUCCESS(productId));
  } catch (err) {
    return dispatch(DELETE_PRODUCT_FAILURE(productId, err));
  }
};

export const setDisplayMode = displayMode => ({
  type: ACTIONS.SET_DISPLAY_MODE,
  displayMode,
});

export const setDisplayBannerFollowProduct = (display, productName) => ({
  type: ACTIONS.SET_DISPLAY_BANNER_FOLLOW_PRODUCT,
  display,
  productName,
});

export const GET_ALL_PRODUCTS_COUNT = () => ({
  type: ACTIONS.GET_ALL_PRODUCTS_COUNT,
});

export const GET_ALL_PRODUCTS_COUNT_SUCCESS = count => ({
  type: ACTIONS.GET_ALL_PRODUCTS_COUNT_SUCCESS,
  count,
});

export const GET_ALL_PRODUCTS_COUNT_FAILURE = err => ({
  type: ACTIONS.GET_ALL_PRODUCTS_COUNT_FAILURE,
  err,
});

export const getAllProductsCount = () => async (dispatch) => {
  dispatch(GET_ALL_PRODUCTS_COUNT());

  try {
    const { count } = await ProductsService.getProductsCount({
      isDraft: false,
    });

    return dispatch(GET_ALL_PRODUCTS_COUNT_SUCCESS(count));
  } catch (err) {
    return dispatch(GET_ALL_PRODUCTS_COUNT_FAILURE(err));
  }
};

export const GET_ALL_MAINTAINED_PRODUCTS_COUNT = () => ({
  type: ACTIONS.GET_ALL_MAINTAINED_PRODUCTS_COUNT,
});

export const GET_ALL_MAINTAINED_PRODUCTS_COUNT_SUCCESS = count => ({
  type: ACTIONS.GET_ALL_MAINTAINED_PRODUCTS_COUNT_SUCCESS,
  count,
});

export const GET_ALL_MAINTAINED_PRODUCTS_COUNT_FAILURE = err => ({
  type: ACTIONS.GET_ALL_MAINTAINED_PRODUCTS_COUNT_FAILURE,
  err,
});

export const getAllMaintainedProductsCount = () => async (dispatch) => {
  dispatch(GET_ALL_MAINTAINED_PRODUCTS_COUNT());
  try {
    const { count } = await ProductsService.getProductsCount({
      isMaintainedByPlatformOrGlobal: true,
      isDraft: false,
    });

    return dispatch(GET_ALL_MAINTAINED_PRODUCTS_COUNT_SUCCESS(
      count,
    ));
  } catch (err) {
    return dispatch(GET_ALL_MAINTAINED_PRODUCTS_COUNT_FAILURE(err));
  }
};

export const GET_PARENT_PRODUCT = productId => ({
  type: ACTIONS.GET_PARENT_PRODUCT,
  productId,
});

export const GET_PARENT_PRODUCT_SUCCESS = product => ({
  type: ACTIONS.GET_PARENT_PRODUCT_SUCCESS,
  product,
});

export const GET_PARENT_PRODUCT_FAILURE = err => ({
  type: ACTIONS.GET_PARENT_PRODUCT_FAILURE,
  err,
});

export const GET_LATEST_PRODUCTS = () => ({
  type: ACTIONS.GET_LATEST_PRODUCTS,
});

export const GET_LATEST_PRODUCTS_SUCCESS = latestCreatedProducts => ({
  type: ACTIONS.GET_LATEST_PRODUCTS_SUCCESS,
  latestCreatedProducts,
});

export const GET_LATEST_PRODUCTS_FAILURE = err => ({
  type: ACTIONS.GET_LATEST_PRODUCTS_FAILURE,
  err,
});

export const getLatestProducts = () => async (dispatch) => {
  dispatch(GET_LATEST_PRODUCTS());

  try {
    const { products } = await ProductsService.getProducts({
      limit: LATEST_PRODUCTS_ITEMS,
      sort: PRODUCT_SORTING.CREATED_DATE_DESC,
      isDraft: false,
      withModules: true,
    });

    return dispatch(GET_LATEST_PRODUCTS_SUCCESS(products));
  } catch (err) {
    return dispatch(GET_LATEST_PRODUCTS_FAILURE(err));
  }
};

export const getParentProduct = ({ parentDigitalProduct }) => async (dispatch) => {
  dispatch(GET_PARENT_PRODUCT(parentDigitalProduct));

  try {
    if (!parentDigitalProduct) {
      // Reset state
      return dispatch(GET_PARENT_PRODUCT_SUCCESS({}));
    }
    const product = await ProductsService.getBigProduct(parentDigitalProduct);
    return dispatch(GET_PARENT_PRODUCT_SUCCESS(product));
  } catch (err) {
    return dispatch(GET_PARENT_PRODUCT_FAILURE(err));
  }
};
