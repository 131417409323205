import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_REPLIES_SUCCESS:
        return {
          ...state,
          [action.productId]: {
            ...state[action.productId],
            [action.suggestionId]: keyBy(action.replies, 'id'),
          },
        };

      case ACTIONS.GET_REPLY_SUCCESS:
      case ACTIONS.CREATE_REPLY_SUCCESS:
        return {
          ...state,
          [action.productId]: {
            ...state[action.productId],
            [action.suggestionId]: {
              ...state[action.productId]?.[action.suggestionId],
              [action.reply.id]: action.reply,
            },
          },
        };

      case ACTIONS.VOTE_REPLY_SUCCESS:
        return {
          ...state,
          [action.productId]: {
            ...state[action.productId],
            [action.suggestionId]: {
              ...state[action.productId]?.[action.suggestionId],
              [action.reply.id]: {
                ...state[action.productId]?.[action.suggestionId]?.[action.reply.id],
                helpful: action.reply.helpful,
              },
            },
          },
        };

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getReplies: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_REPLIES:
          return state + 1;
        case ACTIONS.GET_REPLIES_SUCCESS:
        case ACTIONS.GET_REPLIES_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    getReply: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_REPLY:
          return state + 1;
        case ACTIONS.GET_REPLY_SUCCESS:
        case ACTIONS.GET_REPLY_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    createReply: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.CREATE_REPLY:
          return state + 1;
        case ACTIONS.CREATE_REPLY_SUCCESS:
        case ACTIONS.CREATE_REPLY_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
    voteReply: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.VOTE_REPLY:
          return state + 1;
        case ACTIONS.VOTE_REPLY_SUCCESS:
        case ACTIONS.VOTE_REPLY_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
