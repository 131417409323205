export const ACTIONS = {
  GET_MACRO_ACTIVITIES: 'BUSINESS_ACTIVITIES/GET_MACRO_ACTIVITIES',
  GET_MACRO_ACTIVITIES_SUCCESS: 'BUSINESS_ACTIVITIES/GET_MACRO_ACTIVITIES_SUCCESS',
  GET_MACRO_ACTIVITIES_FAILURE: 'BUSINESS_ACTIVITIES/GET_MACRO_ACTIVITIES_FAILURE',

  GET_MACRO_ACTIVITY: 'BUSINESS_ACTIVITIES/GET_MACRO_ACTIVITY',
  GET_MACRO_ACTIVITY_SUCCESS: 'BUSINESS_ACTIVITIES/GET_MACRO_ACTIVITY_SUCCESS',
  GET_MACRO_ACTIVITY_FAILURE: 'BUSINESS_ACTIVITIES/GET_MACRO_ACTIVITY_FAILURE',

  GET_ACTIVITIES: 'BUSINESS_ACTIVITIES/GET_ACTIVITIES',
  GET_ACTIVITIES_SUCCESS: 'BUSINESS_ACTIVITIES/GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIES_FAILURE: 'BUSINESS_ACTIVITIES/GET_ACTIVITIES_FAILURE',

  GET_ACTIVITY: 'BUSINESS_ACTIVITIES/GET_ACTIVITY',
  GET_ACTIVITY_SUCCESS: 'BUSINESS_ACTIVITIES/GET_ACTIVITY_SUCCESS',
  GET_ACTIVITY_FAILURE: 'BUSINESS_ACTIVITIES/GET_ACTIVITY_FAILURE',

  GET_ATOMIC_ACTIVITIES: 'BUSINESS_ACTIVITIES/GET_ATOMIC_ACTIVITIES',
  GET_ATOMIC_ACTIVITIES_SUCCESS: 'BUSINESS_ACTIVITIES/GET_ATOMIC_ACTIVITIES_SUCCESS',
  GET_ATOMIC_ACTIVITIES_FAILURE: 'BUSINESS_ACTIVITIES/GET_ATOMIC_ACTIVITIES_FAILURE',

  GET_ATOMIC_ACTIVITY: 'BUSINESS_ACTIVITIES/GET_ATOMIC_ACTIVITY',
  GET_ATOMIC_ACTIVITY_SUCCESS: 'BUSINESS_ACTIVITIES/GET_ATOMIC_ACTIVITY_SUCCESS',
  GET_ATOMIC_ACTIVITY_FAILURE: 'BUSINESS_ACTIVITIES/GET_ATOMIC_ACTIVITY_FAILURE',
};
