import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS:
        return keyBy(action.history, 'productId');
      default:
        return state;
    }
  },

  isLoading: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.GET_CURRENT_USER_PRODUCTS_HISTORY:
      case ACTIONS.POST_CURRENT_USER_PRODUCTS_HISTORY:
        return state + 1;
      case ACTIONS.GET_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS:
      case ACTIONS.GET_CURRENT_USER_PRODUCTS_HISTORY_FAILURE:
      case ACTIONS.POST_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS:
      case ACTIONS.POST_CURRENT_USER_PRODUCTS_HISTORY_FAILURE:
        return state - 1;
      default:
        return state;
    }
  },
});
