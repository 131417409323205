export const userIsProductDomainLeader
  = (user, product) => user.domainsWhereUserIsLeader?.some(id => id === product.domainId);

export const userIsProductCommunityArchitect
  = (user, product) => user.communitiesWhereUserIsArchitect?.some(id => id === product.communityId);

export const userIsBusinessUnitArchitect
  = (user, product) => user.businessUnitsWhereUserIsArchitect?.some(
    id => id === product.businessUnitInCharge,
  );

export const userIsManager = (user, product) => product.teamMembers.some(
  el => el.externalId === user.externalId
      && (el?.role?.id === '62e9130f-36c7-4173-85ff-ddb9dfadca98'
        || el?.role?.id === 'b668dcf8-dec0-47d6-b8a8-306a21c2c1f3'),
);
