import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';

import { DISPLAY_MODES, PRODUCT_STAGES } from 'config/constants';
import { selectIsCompactBreakpoint } from 'redux/responsive/selectors';
import { selectCurrentUser, selectCurrentUserCreatedProductMap } from 'redux/users/selectors';
import { selectCommunitiesMap, selectDomainsMap } from 'redux/communities/selectors';
import { selectBusinessUnitsMap } from 'redux/businessUnits/selectors';
import { fromFormToStore } from 'services/products/product-form.mapper';
import {
  userCanAddComponent, userCanDeleteProduct,
  userCanEditProduct,
  userCanEditProductAdditionalInfo,
} from 'services/products/product-business-rules';

export const selectProductsMap = state => state.products.map;

export const selectDetailedProductsMap = state => state.products.map;

export const selectProductsList = createSelector(
  selectProductsMap,
  productsMap => Object.values(productsMap),
);

export const selectProductsByIds = createSelector(
  [
    selectProductsMap,
    (_, ids) => ids,
  ],
  // Use ids.map instead of pick(map, ids) to keep the ids order.
  (productsMap, ids) => ids.map(id => (productsMap[id]?.stage !== PRODUCT_STAGES.ARCH ? productsMap[id] : '')).filter(Boolean),
);

export const selectProductsTotalCount = state => state.products.count;

export const selectProductById
  = (state, id) => selectProductsMap(state)[id] ?? selectCurrentUserCreatedProductMap(state)[id];

export const selectDetailedProductById = (state, id) => selectDetailedProductsMap(state)[id];

export const selectProductsTranslationsMap
  = state => state.products.translationsMap;

export const selectProductTranslationsMap
  = (state, productId) => selectProductsTranslationsMap(state)[productId];

export const selectProductTranslation
  = (state, productId, lang) => selectProductTranslationsMap(state, productId)?.[lang];

export const selectProductFormValuesAsStoreProduct = createSelector(
  (state, formName) => getFormValues(formName)(state),
  formValues => fromFormToStore(formValues || {}),
);

export const selectAreProductsLoading = state => !!state.products.isLoading.getProducts;

export const selectIsProductLoading
  = state => !!Object.keys(state.products.isLoading.getProduct).length;

export const selectIsProductLoadingById
  = (state, id) => !!state.products.isLoading.getProduct[id];

export const selectIsFollowProductLoading
  = ({ products }, id) => !!products.isLoading.followProduct[id];

export const selectAreCreateProductLoading
  = state => !!Object.values(state.products.isLoading.createProduct)
    .filter(Boolean).length;

export const selectIsCreateProductLoading
  = (state, formName) => !!state.products.isLoading.createProduct[formName];

export const selectAreEditProductLoading
  = state => !!Object.values(state.products.isLoading.editProduct)
    .filter(Boolean).length;

export const selectIsDeleteProductLoading
  = (state, productId) => !!state.products.isLoading.deleteProduct[productId];

export const selectAreDeleteProductLoading
  = state => !!Object.values(state.products.isLoading.deleteProduct).some(Boolean);

export const selectIsEditProductLoading
  = (state, formName) => !!state.products.isLoading.editProduct[formName];

export const selectIsFormLoading = formName => state => !!(
  selectIsCreateProductLoading(state, formName)
  || selectIsEditProductLoading(state, formName)
);

export const selectIsProductFollowed = (state, id) => !!selectProductById(state, id)?.followed;

export const selectProductFollowersCount
  = (state, id) => selectProductById(state, id)?.followersCount;

export const selectDisplayMode = state => state.products.displayMode;

export const selectDisplayModeWithResponsive = state => (selectIsCompactBreakpoint(state) ?
  DISPLAY_MODES.GRID_COMPACT
  : selectDisplayMode(state));

export const isFollowingLoading = createSelector(
  state => state.products.isLoading.followProduct,
  loadingFollowing => Object.values(loadingFollowing)
    .some(v => v),
);

export const selectIsLoadingMasterData = state => !!state.products.isLoading.masterData;

export const isLoadingProducts = createSelector(
  [
    selectAreProductsLoading,
    selectIsProductLoading,
    isFollowingLoading,
    selectAreCreateProductLoading,
    selectAreEditProductLoading,
    selectAreDeleteProductLoading,
    selectIsLoadingMasterData,
  ],
  (...args) => args.some(v => v),
);

export const selectDisplayBannerFollowProduct = state => state.products.displayBannerFollowProduct;

export const selectLastFollowProductName = state => state.products.lastFollowProductName;

export const selectFormParentProduct = state => state.products.formParentProduct;

export const selectCanAddComponent = createSelector(
  [
    selectCurrentUser,
    selectProductById,
  ],
  (user, product) => userCanAddComponent(
    user,
    product,
    user?.isPrivileged,
  ),
);

export const selectCanEditProduct = createSelector(
  [
    selectCurrentUser,
    selectProductById,
    selectCommunitiesMap,
    selectDomainsMap,
    selectBusinessUnitsMap,
  ],
  (user, product, communities, domains, businessUnits) => userCanEditProduct(
    user?.externalId,
    product,
    communities[product?.communityId],
    domains[product?.domainId],
    businessUnits[product?.businessUnitInCharge],
    user?.isPrivileged,
  ),
);

export const selectCanDeleteProduct = createSelector(
  [
    selectCurrentUser,
    selectProductById,
    selectCommunitiesMap,
    selectDomainsMap,
    selectBusinessUnitsMap,
  ],
  (user, product) => userCanDeleteProduct(
    user?.externalId,
    product,
  ),
);

export const selectCanEditProductAdditionalInfo = createSelector(
  [
    selectCurrentUser,
    selectProductById,
    selectCommunitiesMap,
    selectDomainsMap,
  ],
  (user, product, communities, domains) => userCanEditProductAdditionalInfo(
    user?.externalId,
    product,
    communities[product?.communityId],
    domains[product?.domainId],
  ),
);

export const selectAllProductsCount = state => state.products.allProductsCount;
export const selectAllMaintainedProductsCount = state => state.products.allMaintainedProductsCount;

export const selectIsAllProductsCountLoading = state => !!state.products.isLoading.allProductsCount;
export const selectIsAllMaintainedProductsCountLoading
  = state => !!state.products.isLoading.allMaintainedProductsCount;

export const selectLatestCreatedProducts = createSelector(
  [
    selectProductsMap,
    state => state.products.latestCreatedProductsIds,
  ],
  (productsMap, ids) => ids.map(id => productsMap[id]).filter(Boolean),
);

export const selectIsLatestProductsLoading
  = state => !!state.products.isLoading.latestCreatedProductsIds;

export const selectProductLocalReferentsByBuId = createSelector(
  [
    selectProductsMap,
    (state, props) => props.productId,
    (state, props) => props.buId,
  ],
  (productsMap, productId, buId) => (
    productId ? productsMap[productId].teamMembers.filter(
      member => member.buReference === buId,
    ) : []
  ),
);

export const selectModulesFromProduct
  = (state, { productId }) => selectProductsMap(state)[productId]?.modules || [];
