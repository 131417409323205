import {
  takeLatest,
  all,
  put,
  select,
} from 'redux-saga/effects';
import { formatRoute } from 'react-router-named-routes';

import ROUTES from 'react/routes';
import { getUnreadNotificationsCount, getNotifications } from './actions';
import { ACTIONS } from './constants';

/**
 * Call actions to update counts.
 */
function* updateNotificationsCounts() {
  const filter = yield select(state => state.notifications.filter);
  const limit = yield select(state => state.notifications.limit);

  yield all([
    put(getUnreadNotificationsCount()),
    put(getNotifications({ limit, types: filter })),
  ]);
}

/**
 * If the list of notifications is displayed, refresh the notifications list.
 */
function* updateNotificationsIfDisplayed() {
  const location = yield select(state => state.router.location);

  // This condition might need fine tuning.
  if (location === formatRoute(ROUTES.NOTIFICATIONS)) {
    yield put(getNotifications());
  }
}

/**
 * Update notifications counts on notifications CRUD operations.
 */
export function* updateNotificationsCountsSaga() {
  // Don't get the counts on start.
  // This will start a race condition between login and refresh_token
  // yield updateNotificationsCounts();

  // Then get the counts on CRUD operations.
  yield takeLatest(
    [
      ACTIONS.SET_NOTIFICATION_READ_SUCCESS,
      ACTIONS.REMOVE_NOTIFICATION_SUCCESS,
      ACTIONS.NEW_NOTIFICATION,
    ],
    updateNotificationsCounts,
  );
}

/**
 * Update the list of notifications if the list of notifications is currently displayed.
 */
export function* updateNotificationsSaga() {
  yield takeLatest(
    [
      ACTIONS.NEW_NOTIFICATION,
    ],
    updateNotificationsIfDisplayed,
  );
}

export default (sagaMiddleware) => {
  sagaMiddleware.run(updateNotificationsCountsSaga);
  sagaMiddleware.run(updateNotificationsSaga);
};
