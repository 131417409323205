import { pick } from 'lodash';
import { createSelector } from 'reselect';
import { localeSorterInObject } from 'services/utils';

export const selectBusinessUnitsMap = state => state.businessUnits.map;

export const selectBusinessUnitsList = createSelector(
  [
    selectBusinessUnitsMap,
  ],
  Object.values,
);

const sorter = localeSorterInObject('name');

export const selectBusinessUnitsListSortedByName = createSelector(
  [
    selectBusinessUnitsList,
  ],
  bus => bus.sort(sorter),
);

export const selectBusinessUnitsCount = state => state.businessUnits.count;
export const selectBusinessUnitsIsLoading = state => state.businessUnits.isLoading.getBusinessUnits;

export const selectBusinessUnitsByIds = (state, ids) => pick(selectBusinessUnitsMap(state), ids);

export const selectBusinessUnitIdsLoadingMap = state => state.businessUnits.idsLoading;

export const selectBusinessUnitIdsList = createSelector(
  [
    selectBusinessUnitsMap,
  ],
  Object.keys,
);

export const selectBusinessUnitIdsLoadingList = createSelector(
  [
    selectBusinessUnitIdsLoadingMap,
  ],
  Object.keys,
);

export const selectBusinessUnitsListByIds = createSelector(
  [
    selectBusinessUnitsByIds,
  ],
  Object.values,
);

export const selectBusinessUnitById = (state, id) => selectBusinessUnitsMap(state)[id];

const aggregateBrandsCountries = createSelector(
  [
    selectBusinessUnitsList,
  ],
  businessUnits => businessUnits.reduce((acc, curr) => {
    acc.countries[curr.country.id] = curr.country;
    acc.brands[curr.brand.id] = curr.brand;

    return acc;
  }, {
    countries: {},
    brands: {},
  }),
);

export const selectCountries = createSelector(
  [
    aggregateBrandsCountries,
  ],
  agg => Object.values(agg.countries).sort(sorter),
);

export const selectBrands = createSelector(
  [
    aggregateBrandsCountries,
  ],
  agg => Object.values(agg.brands).sort(sorter),
);
