import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { ACTIONS } from 'redux/communities/constants';

export default combineReducers({
  count: (state = null, action) => {
    switch (action.type) {
      case ACTIONS.GET_COMMUNITIES_SUCCESS:
        return action.count;
      default:
        return state;
    }
  },

  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_COMMUNITIES_SUCCESS:
        // If we have the domains in the communities, remove them.
        if (action.query?.populateDomains) {
          return keyBy(action.communities.map(({ domains, ...community }) => community), 'id');
        }
        return keyBy(action.communities, 'id');

      case ACTIONS.GET_DOMAIN_SUCCESS:
        return action.community ?
          { ...state, [action.community.id]: action.community }
          : state;

      default:
        return state;
    }
  },

  domainsMap: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_COMMUNITY_DOMAINS_SUCCESS:
        return {
          ...state,
          ...keyBy(action.domains, 'id'),
        };

      case ACTIONS.GET_COMMUNITIES_SUCCESS:
        return (action.query?.populateDomains) ?
          {
            ...state,
            ...action.communities.reduce(
              (res, community) => ({
                ...res,
                ...keyBy(community.domains.items, 'id'),
              }),
              {},
            ),
          }
          : state;

      case ACTIONS.GET_DOMAIN_SUCCESS:
        return {
          ...state,
          [action.domain.id]: action.domain,
        };

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getCommunities: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_COMMUNITIES:
          return state + 1;
        case ACTIONS.GET_COMMUNITIES_SUCCESS:
        case ACTIONS.GET_COMMUNITIES_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getCommunityDomains: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_COMMUNITY_DOMAINS:
          return state + 1;
        case ACTIONS.GET_COMMUNITY_DOMAINS_SUCCESS:
        case ACTIONS.GET_COMMUNITY_DOMAINS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getDomain: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_DOMAIN:
          return state + 1;
        case ACTIONS.GET_DOMAIN_SUCCESS:
        case ACTIONS.GET_DOMAIN_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
