import { defineMessages } from 'react-intl';

export default defineMessages({
  AUTOSAVE_CREATE_SUCCESS: {
    id: 'products.form.autosave.createSuccess',
    defaultMessage: 'Your product was saved automatically as a draft. You can find it in your space.',
  },

  AUTOSAVE_SUCCESS: {
    id: 'products.form.autosave.success',
    defaultMessage: 'Your draft was updated successfully.',
  },

  AUTOSAVE_CREATE_FAILURE: {
    id: 'products.form.autosave.createFailure',
    defaultMessage: 'Your product could not be saved automatically as a draft.',
  },

  AUTOSAVE_FAILURE: {
    id: 'products.form.autosave.failure',
    defaultMessage: 'Your draft could not be updated.',
  },
});
