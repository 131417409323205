import sanitizeHtml from 'sanitize-html';
import marked from 'marked';
import stripHtml from 'string-strip-html';

export const sanitize = html => sanitizeHtml(
  html || '',
  {
    allowedTags: [
      ...sanitizeHtml.defaults.allowedTags,
      'img',
      'h1',
      'h2',
      'h3',
      'h4',
    ],

    // Only allow src attribute on imgs.
    allowedAttributes: {
      img: ['src'],
    },

    // Only allow images with https.
    allowedSchemesByTag: {
      img: ['https'],
    },
  },
);

export const markdownToHtml = marked;

/**
 * Check if a html string only contains whitespaces.
 *
 * @param {string} htmlContent - HTML as a string.
 * @returns {boolean} True if only whitespaces.
 */
export function isHtmlContentEmpty(htmlContent) {
  return !stripHtml(htmlContent || '').trim();
}
