import { push } from 'connected-react-router';
import { formatRoute } from 'react-router-named-routes';
import ROUTES from 'react/routes';

export const errorHandler = store => next => (action) => {
  if (action.error && action.error instanceof Error && !action.noRedirect) {
    store.dispatch(push(formatRoute(ROUTES.ERRORS, { code: action.error.status })));
  }
  return next(action);
};
