import { keyBy, omit } from 'lodash';
import { combineReducers } from 'redux';

import { DISPLAY_MODES } from 'config/constants';

import { ACTIONS } from 'redux/products/constants';
import { ACTIONS as USER_ACTIONS } from 'redux/users/constants';

export default combineReducers({
  count: (state = null, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCT:
        return action.preserve ? state : null;
      case ACTIONS.GET_PRODUCTS_SUCCESS:
        return action.count;
      default:
        return state;
    }
  },

  allProductsCount: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.GET_ALL_PRODUCTS_COUNT_SUCCESS:
        return action.count;
      default:
        return state;
    }
  },

  allMaintainedProductsCount: (state = 0, action) => {
    switch (action.type) {
      case ACTIONS.GET_ALL_MAINTAINED_PRODUCTS_COUNT_SUCCESS:
        return action.count;
      default:
        return state;
    }
  },

  latestCreatedProductsIds: (state = [], action) => {
    switch (action.type) {
      case ACTIONS.GET_LATEST_PRODUCTS_SUCCESS:
        return action.latestCreatedProducts.map(product => product.id);
      default:
        return state;
    }
  },

  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCTS_SUCCESS: {
        const newProducts = keyBy(action.products, 'id');

        return action.preserve ?
          { ...state, ...newProducts }
          : newProducts;
      }

      case ACTIONS.GET_PRODUCTS_OR_COMPONENTS_SUCCESS: {
        const newProducts = keyBy(action.products, 'id');

        return action.preserve ?
          { ...state, ...newProducts }
          : newProducts;
      }

      case ACTIONS.GET_LATEST_PRODUCTS_SUCCESS:
        return {
          ...state,
          ...keyBy(action.latestCreatedProducts, 'id'),
        };

      case ACTIONS.GET_PRODUCT_SUCCESS:
        return !action.query.lang ? {
          ...state,
          [action.product.id]: action.product,
        } : state;

      case USER_ACTIONS.FOLLOW_PRODUCT_SUCCESS:
      case USER_ACTIONS.UNFOLLOW_PRODUCT_SUCCESS: {
        const diff = (action.type === USER_ACTIONS.FOLLOW_PRODUCT_SUCCESS ? +1 : -1);
        return keyBy(
          Object.values(state).map(product => ({
            ...product,
            followed: action.followedProducts.includes(product.id),
            followersCount: product.id === action.id ?
              product.followersCount + diff
              : product.followersCount,
          })),
          'id',
        );
      }

      case ACTIONS.REMOVE_PRODUCT_FROM_MAP: {
        return omit(state, [action.id]);
      }

      default:
        return state;
    }
  },

  translationsMap: (state = {}, action) => {
    switch (action.type) {
      // Clear translations cache when getting the product.
      case ACTIONS.GET_PRODUCT:
        return omit(state, [action.productId]);

      // Store translations of the product on success.
      case ACTIONS.GET_PRODUCT_SUCCESS:
        return action.query.lang ? {
          ...state,
          [action.product.id]: {
            ...state[action.product.id],
            [action.query.lang]: action.product,
          },
        } : state;

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getProducts: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_PRODUCTS:
          return state + 1;
        case ACTIONS.GET_PRODUCTS_SUCCESS:
        case ACTIONS.GET_PRODUCTS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getProduct: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.GET_PRODUCT:
          return {
            ...state,
            [action.id]: true,
          };
        case ACTIONS.GET_PRODUCT_SUCCESS:
          return omit(state, [action.product.id]);
        case ACTIONS.GET_PRODUCT_FAILURE:
          return omit(state, [action.id]);
        default:
          return state;
      }
    },

    followProduct: (state = {}, { type, id }) => {
      switch (type) {
        case USER_ACTIONS.FOLLOW_PRODUCT:
        case USER_ACTIONS.UNFOLLOW_PRODUCT:
          return {
            ...state,
            [id]: (state[id] || 0) + 1,
          };
        case USER_ACTIONS.FOLLOW_PRODUCT_SUCCESS:
        case USER_ACTIONS.FOLLOW_PRODUCT_FAILURE:
        case USER_ACTIONS.UNFOLLOW_PRODUCT_SUCCESS:
        case USER_ACTIONS.UNFOLLOW_PRODUCT_FAILURE:
          return {
            ...state,
            [id]: (state[id] || 0) - 1,
          };
        default:
          return state;
      }
    },

    createProduct: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.CREATE_PRODUCT:
          return {
            ...state,
            [action.formName]: true,
          };

        case ACTIONS.CREATE_PRODUCT_SUCCESS:
        case ACTIONS.CREATE_PRODUCT_FAILURE:
          return omit(state, [action.formName]);

        default:
          return state;
      }
    },

    editProduct: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.EDIT_PRODUCT:
          return {
            ...state,
            [action.formName]: true,
          };

        case ACTIONS.EDIT_PRODUCT_SUCCESS:
        case ACTIONS.EDIT_PRODUCT_FAILURE:
          return omit(state, [action.formName]);

        default:
          return state;
      }
    },

    deleteProduct: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.DELETE_PRODUCT:
          return {
            ...state,
            [action.productId]: true,
          };
        case ACTIONS.DELETE_PRODUCT_SUCCESS:
        case ACTIONS.DELETE_PRODUCT_FAILURE:
          return omit(state, [action.productId]);
        default:
          return state;
      }
    },

    allProductsCount: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_ALL_PRODUCTS_COUNT:
          return state + 1;
        case ACTIONS.GET_ALL_PRODUCTS_COUNT_SUCCESS:
        case ACTIONS.GET_ALL_PRODUCTS_COUNT_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    allMaintainedProductsCount: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_ALL_MAINTAINED_PRODUCTS_COUNT:
          return state + 1;
        case ACTIONS.GET_ALL_MAINTAINED_PRODUCTS_COUNT_SUCCESS:
        case ACTIONS.GET_ALL_MAINTAINED_PRODUCTS_COUNT_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    latestCreatedProductsIds: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_LATEST_PRODUCTS:
          return state + 1;
        case ACTIONS.GET_LATEST_PRODUCTS_SUCCESS:
        case ACTIONS.GET_LATEST_PRODUCTS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),

  displayMode: (state = DISPLAY_MODES.GRID, action) => {
    switch (action.type) {
      case ACTIONS.SET_DISPLAY_MODE:
        return action.displayMode;
      default:
        return state;
    }
  },

  displayBannerFollowProduct: (state = false, action) => {
    switch (action.type) {
      case ACTIONS.SET_DISPLAY_BANNER_FOLLOW_PRODUCT:
        return action.display;
      default:
        return state;
    }
  },

  lastFollowProductName: (state = '', action) => {
    switch (action.type) {
      case ACTIONS.SET_DISPLAY_BANNER_FOLLOW_PRODUCT:
        return action.productName || '';
      default:
        return state;
    }
  },

  formParentProduct: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_PARENT_PRODUCT_SUCCESS:
        return action.product;
      case ACTIONS.GET_PARENT_PRODUCT_FAILURE:
        return {};
      default: return state;
    }
  },
});
