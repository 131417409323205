import { combineReducers } from 'redux';
import { ACTIONS } from 'redux/app/constants';

export default combineReducers({
  isConnected: (state = true, action) => {
    switch (action.type) {
      case ACTIONS.UPDATE_CONNECTIVITY:
        return action.isConnected;
      default:
        return state;
    }
  },

});
