import memoize from 'memoize-one';
import { get } from 'lodash';

const EMPTY_OBJECT = {};

/**
 * A "noop" like function that always return the same empty object.
 *
 * @returns {object} - The empty object.
 */
export const getEmptyObject = () => EMPTY_OBJECT;

/**
 * Sort two objects in the numeric order of one of their key.
 *
 * @param {string} index - Index of the key, in a lodash.get format.
 * @returns {function(object, object): number} - Sort result (0, 1 or -1).
 */
export const numericSorterInObject = index => (a, b) => get(a, index, 0) - get(b, index, 0);

/**
 * Sort two objects in the alphabetic order of one of their key.
 *
 * @param {string} index - Index of the key, in a lodash.get format.
 * @returns {function(object, object): number} - Sort result (0, 1 or -1).
 */
export const localeSorterInObject = index => (a, b) => get(a, index, '').localeCompare(get(b, index, ''));

export const memoizedSlices = memoize((array, start, end) => array.slice(start, end));

export const ensureArray = elt => (elt ? [].concat(elt) : []);

export const padArray = (array, minLength, elementFactory) => {
  const paddedArray = [...array];
  for (let i = paddedArray.length; i < minLength; i++) {
    paddedArray.push(elementFactory(i, paddedArray));
  }
  return paddedArray;
};

/**
 * Returns current value in a ease-in-out cubic bezier function.
 * Represents f: a € [0, d] => [b, c].
 *
 * @param {number} a - Current time value in [0, duration].
 * @param {number} b - Initial value.
 * @param {number} c - Target value.
 * @param {number} d - Duration.
 * @returns {number} - Result of f(a).
 */
export const easeInOutQuad = (a, b, c, d) => {
  let t = a;
  t /= (d / 2);
  if (t < 1) {
    return (c / 2 * t * t) + b;
  }
  t--;
  return -(c / 2 * ((t * (t - 2)) - 1)) + b;
};

/**
 * Scroll element to "to" scroll position in "duration" ms.
 *
 * @param {Node} element - The element to scroll.
 * @param {number} to - The target scroll position.
 * @param {number} duration - The duration of the ease-in-out animation.
 */
export const scrollTopTo = (element, to, duration) => {
  const from = element.scrollTop;
  const diff = to - from;
  const timeIncrement = 20;
  let currentTime = 0;
  const animateScroll = () => {
    currentTime += timeIncrement;
    element.scrollTop = easeInOutQuad(currentTime, from, diff, duration);
    if (currentTime < duration) {
      setTimeout(animateScroll, timeIncrement);
    }
  };
  animateScroll();
};

/**
 * Extract .js filename from a string (eg url).
 *
 * @param {string} url - The input string.
 * @returns {string} - The filename if found, empty string if not.
 */
export const extractScriptName = (url = '') => {
  const [, scriptName = ''] = url.match(/([^/]*\.js)$/) || [];
  return scriptName;
};
