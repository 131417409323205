import moment from 'moment';

/**
 * Returns browser defined language if the browser allows it.
 *
 * @returns {string} - The browser defined language.
 */
export const getBrowserLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    return navigator.languages[0].substr(0, 2);
  }
  if (navigator.userLanguage) {
    // IE only
    return navigator.userLanguage.substr(0, 2);
  }
  if (navigator.language) {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    return navigator.language.substr(0, 2);
  }
  return '';
};

/**
 * Writes locale in document.
 *
 * @param {string} locale - Locale.
 */
export const setHtmlLangAttribute = (locale) => {
  // eslint-disable-next-line no-unused-expressions
  document?.getElementsByTagName('html')?.[0]?.setAttribute('lang', locale);
};

/**
 * Import locale bundle.
 * Fetches it from server, using import() bundle splitting.
 *
 * @async
 * @param {string} locale - Current locale.
 * @returns {Promise<object>} - The imported bundle.
 */
export const importLocaleBundle = async (locale = 'en') => {
  const bundles = await Promise.all([
    // Ignoring warning on dynamic import. I will not bundle the locales in main.
    // eslint-disable-next-line
    import('locales/' + locale),
  ]);

  const bundle = bundles[0].default;
  moment.locale(locale);
  setHtmlLangAttribute(locale);

  return {
    ...bundle,
    locale,
  };
};

