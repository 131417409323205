import { combineReducers } from 'redux';
import { ACTIONS } from 'redux/architectureBuildingBlocks/constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY_SUCCESS:
        return {
          ...state,
          [action.communityId]: action.abbs,
        };

      case ACTIONS.GET_ALL_ARCHITECTURE_BUILDING_BLOCKS_SUCCESS:
      {
        const result = action.abbs.reduce((map, current) => {
          if (current.community) {
            map[current.community] = [...(map[current.community] || []), current];
          }
          return map;
        }, {});
        return result;
      }

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getCommunityArchitectureBuildingBlocks: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY:
          return state + 1;
        case ACTIONS.GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY_SUCCESS:
        case ACTIONS.GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getAllArchitectureBuildingBlocks: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_ALL_ARCHITECTURE_BUILDING_BLOCKS:
          return state + 1;
        case ACTIONS.GET_ALL_ARCHITECTURE_BUILDING_BLOCKS_SUCCESS:
        case ACTIONS.GET_ALL_ARCHITECTURE_BUILDING_BLOCKS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
