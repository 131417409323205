export const ACTIONS = {
  GET_REPLIES: 'SUGGESTION_REPLIES/GET_REPLIES',
  GET_REPLIES_SUCCESS: 'SUGGESTION_REPLIES/GET_REPLIES_SUCCESS',
  GET_REPLIES_FAILURE: 'SUGGESTION_REPLIES/GET_REPLIES_FAILURE',

  GET_REPLY: 'SUGGESTION_REPLIES/GET_REPLY',
  GET_REPLY_SUCCESS: 'SUGGESTION_REPLIES/GET_REPLY_SUCCESS',
  GET_REPLY_FAILURE: 'SUGGESTION_REPLIES/GET_REPLY_FAILURE',

  CREATE_REPLY: 'SUGGESTION_REPLIES/CREATE_REPLY',
  CREATE_REPLY_SUCCESS: 'SUGGESTION_REPLIES/CREATE_REPLY_SUCCESS',
  CREATE_REPLY_FAILURE: 'SUGGESTION_REPLIES/CREATE_REPLY_FAILURE',

  VOTE_REPLY: 'SUGGESTION_REPLIES/VOTE_REPLY',
  VOTE_REPLY_SUCCESS: 'SUGGESTION_REPLIES/VOTE_REPLY_SUCCESS',
  VOTE_REPLY_FAILURE: 'SUGGESTION_REPLIES/VOTE_REPLY_FAILURE',
};
