import agent from 'services/http';

// import config from 'config';
export const getLoginUrl = () => {
  let redirectUrl = window.location.href;

  // Remove '/error/401'
  // to avoid the user to be successfully connected and seeing the 401 page anyway
  if (redirectUrl.includes('401')) {
    redirectUrl = redirectUrl.replace(/\/error\/401/g, '');
  }

  // Construct the login URL with the updated redirect URL
  return `/auth?RelayState=${redirectUrl}`;
};

export const redirectToLogin = () => {
  window.location.replace(getLoginUrl());
};

/* *
 * Run request with authorization info
 * Get the list of products.
 *
 * @async
 */
export const runWithAuth = async () => {
  // is the user authenticated ?
  try {
    const res = await agent.get('/auth/authenticated');
    if (res.status !== 200) {
      redirectToLogin();
      // redirect the user for auth process
      return false;
    }
    return true;
  } catch {
    redirectToLogin();
  }
  return false;
};

// Define a response interceptor for 401
agent.on('error', (err) => {
  if (err.status === 401) {
    redirectToLogin();
  }
});
