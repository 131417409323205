import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';

import { padArray } from 'services/utils';
import { htmlToEditorState, editorStateToHtml } from 'react/generic/form/wysiwyg/wysiwyg.utils';
import {
  featureFactory,
  screenshotsFactory,
  moduleFactory,
  linkFactory,
} from 'services/products/products-form';

import {
  MIN_FEATURES_LENGTH,
  MIN_SCREENSHOTS_LENGTH,
  MIN_MODULES_LENGTH,
  MIN_LINKS_LENGTH,
} from 'config/constants';

const extractLinksForForm = links => padArray(
  links.map(l => ({ id: uuid(), ...l })),
  MIN_LINKS_LENGTH,
  linkFactory,
);

const linkFilter = link => link && !isEmpty(link);

const linkMapper = ({ title, link }) => ({
  title,
  link,
});

export const fromStoreToForm = ({
  name,
  isGlobal,
  domainId,
  community,
  isMaintainedByPlatform,
  teamMembers,
  mainFeatures,
  links,
  screenshots,
  tags,
  businessUnitsUsingProduct,
  businessUnitInCharge,
  presentation,
  atomicActivities = [],
  modules = [],
  softwarePublisher,
  parentDigitalProduct,
  localDomain,
  ...product
}) => {
  // If product is big product, then we need the contactTeamMembers key
  let teamMembersKey = 'contactTeamMembers';
  if ((parentDigitalProduct || parentDigitalProduct)?.id) {
    teamMembersKey = 'teamMembers';
  }

  return ({
    ...product,
    name: name?.trim(),
    isGlobal: isGlobal ? 'true' : 'false',
    domain: domainId ? {
      value: domainId,
    } : null,
    community: community ? {
      value: community.id,
      label: community.name,
    } : null,
    isMaintainedByPlatform: isMaintainedByPlatform ? 'true' : 'false',
    mainFeatures: padArray(
      (mainFeatures || []).map(f => ({ id: uuid(), label: f })),
      MIN_FEATURES_LENGTH,
      featureFactory,
    ),
    modules: padArray(
      modules || [],
      MIN_MODULES_LENGTH,
      moduleFactory,
    ),
    linksSources: extractLinksForForm(links?.source || []),
    linksApiReference: extractLinksForForm(links?.apiReference || []),
    linksDiscussion: extractLinksForForm(links?.discussion || []),
    linksProductUrl: extractLinksForForm(links?.productUrl || []),
    linksProductMarketingUrl: extractLinksForForm(links?.productMarketingUrl || []),
    linksDocumentation: extractLinksForForm(links?.documentation || []),
    linksRoadmap: extractLinksForForm(links?.roadmap || []),
    linksBacklog: extractLinksForForm(links?.backlog || []),
    linksSupport: extractLinksForForm(links?.support || []),
    [teamMembersKey]: (teamMembers || []).map(({ role, externalId, buReference }) => ({
      role: {
        value: role?.id,
        label: role?.label,
        displayOnTop: role?.displayOnTop,
        isProductLeader: role?.isProductLeader,
        isBuSuitable: role?.isBuSuitable,
      },
      buReference: {
        value: buReference,
      },
      user: {
        value: {
          externalId,
        },
      },
    })),
    presentation: htmlToEditorState(presentation),
    screenshots: padArray(
      screenshots || [],
      MIN_SCREENSHOTS_LENGTH,
      screenshotsFactory,
    ),
    tags: {
      techno: tags?.techno?.map(t => ({ value: t.id, label: t.label })),
      devices: tags?.devices?.map(d => ({ value: d.id, label: d.label })),
      misc: tags?.misc?.map(d => ({ value: d.id, label: d.label })),
    },
    businessUnitsUsingProduct: (businessUnitsUsingProduct || []).map(bu => ({ value: bu })),
    businessUnitInCharge: businessUnitInCharge ? { value: businessUnitInCharge } : null,
    atomicActivities,
    softwarePublisher: softwarePublisher ? {
      value: softwarePublisher.id,
      label: softwarePublisher.name,
    } : null,
    parentDigitalProduct: typeof parentDigitalProduct === 'string' ? parentDigitalProduct : parentDigitalProduct?.id,
    localDomain: localDomain ? {
      value: localDomain,
    } : null,
  });
};

export const fromFormToStore = ({
  id,
  name,
  domain,
  isMaintainedByPlatform,
  community,
  businessUnitInCharge,
  teamMembers = [],
  businessUnitsUsingProduct = [],
  mainFeatures = [],
  screenshots = [],
  modules = [],
  linksSources = [],
  linksApiReference = [],
  linksDiscussion = [],
  linksProductUrl = [],
  linksDocumentation = [],
  linksRoadmap = [],
  linksBacklog = [],
  linksSupport = [],
  linksProductMarketingUrl = [],
  tags,
  isGlobal,
  presentation,
  softwarePublisher,
  personalData,
  parentDigitalProduct,
  contactTeamMembers,
  localDomain,
  ...formValues
}) => {
  const foundTeamMembers = teamMembers?.length ? teamMembers : contactTeamMembers;
  return ({
    id,
    name: name?.trim(),
    domainId: domain?.value,
    teamMembers: (foundTeamMembers || [])
      .map(({ role, user, buReference }) => ({
        role: {
          id: role?.value,
          label: role?.label,
          displayOnTop: role?.displayOnTop,
          isProductLeader: role?.isProductLeader,
          isBuSuitable: role?.isBuSuitable,
        },
        externalId: user?.value?.externalId,
        buReference: buReference?.value,
      }))
      .filter(({ externalId }) => !!externalId),
    links: {
      source: linksSources.filter(linkFilter).map(linkMapper),
      apiReference: linksApiReference.filter(linkFilter).map(linkMapper),
      discussion: linksDiscussion.filter(linkFilter).map(linkMapper),
      productUrl: linksProductUrl.filter(linkFilter).map(linkMapper),
      productMarketingUrl: linksProductMarketingUrl.filter(linkFilter).map(linkMapper),
      documentation: linksDocumentation.filter(linkFilter).map(linkMapper),
      roadmap: linksRoadmap.filter(linkFilter).map(linkMapper),
      backlog: linksBacklog.filter(linkFilter).map(linkMapper),
      support: linksSupport.filter(linkFilter).map(linkMapper),
    },
    businessUnitInCharge: businessUnitInCharge?.value || null,
    businessUnitsUsingProduct:
      (businessUnitsUsingProduct || []).map(bu => bu.value).filter(Boolean),
    mainFeatures: (mainFeatures || []).map(f => f.label).filter(Boolean),
    screenshots: (screenshots || []).filter(s => !isEmpty(s.url)),
    tags: {
      techno: (tags?.techno || []).map(t => ({ id: t.value, label: t.label })),
      devices: (tags?.devices || []).map(d => ({ id: d.value, label: d.label })),
      misc: (tags?.misc || []).map(d => ({ id: d.value, label: d.label })),
    },
    isGlobal: isGlobal === 'true',
    isMaintainedByPlatform: isMaintainedByPlatform === 'true',
    presentation: presentation ? editorStateToHtml(presentation) : '',
    modules: (modules || [])
      .filter(m => !isEmpty(m.title))
      .map(module => ({ ...module, product: id })),
    softwarePublisher: softwarePublisher?.value,
    communityId: community?.value,
    personalData: personalData === 'N/A' ? null : personalData === 'TRUE',
    ...formValues,
    parentDigitalProduct: parentDigitalProduct ? { id: parentDigitalProduct } : null,
    localDomain: localDomain?.value,
  });
};
