import { combineReducers } from 'redux';

import { ACTIONS } from './constants';

export default combineReducers({
  results: (state = null, action) => {
    switch (action.type) {
      case ACTIONS.SET_SEARCH_RESULTS:
        return action.results;
      default:
        return state;
    }
  },

  isLoading: combineReducers({
    searchByTitle: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.SEARCH_BY_TITLE:
          return state + 1;
        case ACTIONS.SEARCH_BY_TITLE_SUCCESS:
        case ACTIONS.SEARCH_BY_TITLE_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),

  errors: combineReducers({
    searchByTitle: (state = null, action) => {
      switch (action.type) {
        case ACTIONS.SEARCH_BY_TITLE:
          return null;
        case ACTIONS.SEARCH_BY_TITLE_FAILURE:
          return action.error;
        default:
          return state;
      }
    },
  }),
});
