import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_LOCAL_ORGANIZATION_CHILDREN_SUCCESS:
        return {
          ...state,
          [action.id]: action.localDomains,
        };

      case ACTIONS.GET_ALL_LOCAL_ORGANIZATION_SUCCESS:
      {
        const result = action.localOrganizations.reduce((map, current) => {
          if (current.id) {
            map[current.id] = [...(map[current.id] || []), current];
          }
          return map;
        }, {});
        return result;
      }

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getLocalOrganizationChildren: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_LOCAL_ORGANIZATION_CHILDREN:
          return state + 1;
        case ACTIONS.GET_LOCAL_ORGANIZATION_CHILDREN_SUCCESS:
        case ACTIONS.GET_LOCAL_ORGANIZATION_CHILDREN_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getAllLocalOrganizations: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_ALL_LOCAL_ORGANIZATION:
          return state + 1;
        case ACTIONS.GET_ALL_LOCAL_ORGANIZATION_SUCCESS:
        case ACTIONS.GET_ALL_LOCAL_ORGANIZATION_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
