import { combineReducers } from 'redux';
import { omit } from 'lodash';

import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_SNIPPET_CONFIGURATION_SUCCESS:
      case ACTIONS.UPSERT_SNIPPET_CONFIGURATION_SUCCESS:
        return {
          ...state,
          [action.productId]: action.snippetConfig,
        };
      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getSnippetConfig: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.GET_SNIPPET_CONFIGURATION:
          return {
            ...state,
            [action.productId]: true,
          };
        case ACTIONS.GET_SNIPPET_CONFIGURATION_FAILURE:
        case ACTIONS.GET_SNIPPET_CONFIGURATION_SUCCESS:
          return omit(state, action.productId);
        default:
          return state;
      }
    },

    upsertSnippetConfig: (state = {}, action) => {
      switch (action.type) {
        case ACTIONS.UPSERT_SNIPPET_CONFIGURATION:
          return {
            ...state,
            [action.productId]: true,
          };
        case ACTIONS.UPSERT_SNIPPET_CONFIGURATION_FAILURE:
        case ACTIONS.UPSERT_SNIPPET_CONFIGURATION_SUCCESS:
          return omit(state, action.productId);
        default:
          return state;
      }
    },
  }),
});
