const polyfills = [
  {
    loader: () => import('resize-observer-polyfill'),
    test: () => !(
      'ResizeObserver' in global
      && 'ResizeObserverEntry' in global
      && 'contentRect' in ResizeObserverEntry.prototype
    ),
  },
];

export default () => Promise.all(
  polyfills
    .filter(polyfill => polyfill.test())
    .map(polyfill => polyfill.loader()),
);
