import superagent from 'superagent';
import { withLocale } from 'services/i18n/i18n.middleware';

const agent = superagent.agent();

// Add token to request
// agent.use(withToken);
// Add locale to requests
agent.use(withLocale);
// Allow retry requests once if token invalid (expired or revoked).
// agent.use(withRefreshTokenIfInvalid(agent));

export default agent;
