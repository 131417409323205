import { groupBy } from 'lodash';
import { createSelector } from 'reselect';

import { localeSorterInObject } from 'services/utils';

export const selectCommunitiesMap = state => state.communities.map;

export const selectCommunityById = (state, communityId) => state.communities.map[communityId];

export const selectCommunitiesList = createSelector(
  selectCommunitiesMap,
  communitiesMap => Object.values(communitiesMap).sort(localeSorterInObject('name')),
);

export const selectCommunitiesCount = state => state.communities.count;

export const selectCommunitiesIsLoading = state => state.communities.isLoading.getCommunities;

export const selectDomainIsLoading = state => state.communities.isLoading.getDomain;

export const selectDomainsMap = state => state.communities.domainsMap;

export const selectDomainsGroupedByCommunities = createSelector(
  selectDomainsMap,
  domainsMap => groupBy(
    domainsMap,
    domain => domain.community?.id,
  ),
);

export const selectCommunityDomains = createSelector(
  [
    selectDomainsGroupedByCommunities,
    (_, communityId) => communityId,
  ],
  (domainsByCommunities, communityId) => (
    [...(domainsByCommunities[communityId] || [])]
      .sort(localeSorterInObject('name'))
  ),
);

export const selectCommunityDomainsIsLoading = state => state
  .communities.isLoading.getCommunityDomains;

export const selectCommunityListWithDomains = createSelector(
  [
    selectCommunitiesList,
    selectDomainsGroupedByCommunities,
    (_, filterEmptyCommunities) => filterEmptyCommunities,
  ],
  (communities, domainsByCommunities, filterEmptyCommunities) => communities
    .map(c => ({
      ...c,
      domains: (domainsByCommunities[c.id]?.sort(localeSorterInObject('name')) || []),
    }))
    .sort(localeSorterInObject('name'))
    .filter(community => !filterEmptyCommunities || !!community.domains.length),
);

export const selectDomainsWithCommunityName = createSelector(
  [
    selectCommunitiesMap,
    selectDomainsMap,
  ],
  (communities, domains) => Object.values(domains).map(domain => ({
    ...domain,
    communityId: domain.community.id,
    communityName: communities[domain.community.id]?.name,
  })),
);

export const selectCommunityOfDomain = createSelector(
  [
    selectDomainsMap,
    selectCommunitiesMap,
    (_, domainId) => domainId,
  ],
  (domains, communities, domainId) => communities[domains[domainId]?.community?.id],
);
