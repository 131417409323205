import { combineReducers } from 'redux';

import { ACTIONS } from './constants';

export default combineReducers({
  addToastStack: (state = [], action) => {
    switch (action.type) {
      case ACTIONS.ADD_TOAST:
        return [
          ...state,
          {
            toast: action.toast,
            appToastId: action.appToastId,
            meta: action.meta,
          },
        ];

      case ACTIONS.ACK_ADD_TOAST:
        return state.filter(({ appToastId }) => appToastId !== action.appToastId);

      default:
        return state;
    }
  },
});
