import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { sanitize as sanitizeHtml } from 'services/html';

/**
 * Convert html string to Draft editor state.
 *
 * @param {string} html - HTML string.
 * @returns {object} Editor state.
 */
export function htmlToEditorState(html) {
  const convertedToDraft = html ? htmlToDraft(html) : null;

  return convertedToDraft ?
    EditorState.createWithContent(ContentState.createFromBlockArray(convertedToDraft.contentBlocks))
    : EditorState.createEmpty();
}

/**
 * Convert Draft editor state to html string.
 *
 * @param {object} editorState - Draft editor state.
 * @param {boolean} [shouldSanitize=true] - Sanitize the html output.
 * @returns {string} Html output.
 */
export function editorStateToHtml(editorState, shouldSanitize = true) {
  const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  return shouldSanitize ?
    sanitizeHtml(html)
    : html;
}
