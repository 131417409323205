export const mapProductFromServer = product => ({ ...product, personalData: product.personalData?.toString().toUpperCase() || 'N/A' });

export const mapProductsFromServer = products => products.map(
  product => (
    { ...product, personalData: product.personalData?.toString().toUpperCase() || 'N/A' }
  ),
);

export const mapProductsFromCompositeView = products => products.map(
  product => ({
    ...product,
    _id: product.componentId,
    personalData: product.personalData?.toString().toUpperCase() || 'N/A',
    stage: product.status ? product.status : product.stage,
  }),
);
