import { combineReducers } from 'redux';
import { ACTIONS } from 'redux/subsets/constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_SUBSETS_BY_COMMUNITY_SUCCESS:
        return {
          ...state,
          [action.communityId]: action.subsets,
        };

      case ACTIONS.GET_ALL_SUBSETS_SUCCESS:
      {
        const result = action.subsets.reduce((map, current) => {
          if (current.communityId) {
            map[current.communityId] = [...(map[current.communityId] || []), current];
          }
          return map;
        }, {});
        return result;
      }

      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getCommunitySubsets: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_SUBSETS_BY_COMMUNITY:
          return state + 1;
        case ACTIONS.GET_SUBSETS_BY_COMMUNITY_SUCCESS:
        case ACTIONS.GET_SUBSETS_BY_COMMUNITY_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    getAllSubsets: (state = 0, { type }) => {
      switch (type) {
        case ACTIONS.GET_ALL_SUBSETS:
          return state + 1;
        case ACTIONS.GET_ALL_SUBSETS_SUCCESS:
        case ACTIONS.GET_ALL_SUBSETS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
