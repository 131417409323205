import agent from 'services/http';
import { mapUserFromServer } from 'services/users/users.mapper';
import { mapNotificationFromServer } from 'services/notifications/notifications.mapper';

const e = encodeURIComponent;

export const getCurrentUser = async () => {
  const { body } = await agent.get('/api/users/me');

  return mapUserFromServer(body || {});
};

export const getUsers = async (query) => {
  const { body } = await agent.get('/api/users').query(query);
  return body.map(mapUserFromServer);
};

export const getUsersByIds = ids => getUsers({ ids });

export const getUsersByExternalIds = externalIds => getUsers({ externalIds });

export const searchUsersByFullName = searchQuery => getUsers({ searchQuery });

export const getWorkplaceId = async (mail) => {
  const { body } = await agent
    .get('/api/users/workplace')
    .query({ mail });

  return body;
};

/**
 * Follow given product by its id.
 *
 * @param {string} productId - The id of the product to follow.
 * @returns {Promise<Array<string>>} - The list of the followed product ids.
 */
export const followProduct = async (productId) => {
  const { body } = await agent.post('/api/users/me/followedProducts').send({ productId });
  return body;
};

/**
 * Unfollow given product by its id.
 *
 * @param {string} productId - The id of the product to unfollow.
 * @returns {Promise<Array<string>>} - The list of the followed product ids.
 */
export const unfollowProduct = async (productId) => {
  const { body } = await agent.del(`/api/users/me/followedProducts/${productId}`);
  return body;
};

/**
 * POST /api/notifications/subscriptions.
 * Post a new subscription.
 *
 * @async
 * @param {string} subscription - Subscription.
 * @returns {object} Http response.
 */
export const postSubscription = subscription => agent
  .post('/api/users/me/subscriptions')
  .send({
    subscription,
  });

/**
 * GET /api/notifications.
 *
 * @async
 * @param {object} query - Query parameters.
 * @returns {Array<object>} Notifications.
 */
export async function getNotifications(query) {
  const { body: { notifications, count } } = await agent.get('/api/users/me/notifications').query(query);
  return {
    notifications: (notifications || []).map(mapNotificationFromServer),
    count: count || 0,
  };
}

/**
 * GET /api/notifications?read=false&limit=0.
 *
 * @async
 * @returns {Array<object>} Notifications.
 */
export async function getUnreadNotificationsCount() {
  return getNotifications({
    read: false,
    limit: 0,
  });
}

/**
 * PATCH /api/notifications/:notificationId.
 *
 * @async
 * @param {string} notificationId - Notification id.
 * @param {boolean} isRead - Is the notification read.
 * @returns {object} Notification updated.
 */
export async function setNotificationRead(notificationId, isRead) {
  const { body } = await agent
    .patch(`/api/users/me/notifications/${e(notificationId)}`)
    .send({
      read: !!isRead,
    });

  return mapNotificationFromServer(body);
}

/**
 * PATCH /api/notifications.
 *
 * @async
 * @param {Array} notificationIds - Notification ids.
 * @returns {object} Notification updated.
 */
export async function setNotificationsAsRead(notificationIds) {
  const { body } = await agent
    .patch('/api/users/me/notifications')
    .send({
      notificationIds,
      read: true,
    });

  return mapNotificationFromServer(body);
}

/**
 * DELETE /api/notifications/:notificationId.
 *
 * @async
 * @param {string} notificationId - Notification id.
 * @returns {object} Http response.
 */
export async function removeNotification(notificationId) {
  return agent.delete(`/api/users/me/notifications/${e(notificationId)}`);
}

/**
 * DELETE /api/notifications.
 *
 * @async
 * @param {string} notificationIds - Ids of the notifications.
 * @returns {object} Http response.
 */
export async function removeNotifications(notificationIds) {
  return agent.delete('/api/users/me/notifications')
    .send({ notificationIds });
}

/**
 * GET /api/user/me/preferences.
 *
 * @async
 * @returns {Array<object>} Preferences.
 */
export async function getCurrentUserPreferences() {
  const { body } = await agent.get('/api/users/me/preferences');
  return body;
}

/**
 * POST /api/user/me/preferences.
 *
 * @async
 * @param {object} preferences - Preferences.
 * @returns {object} - New preferences.
 */
export async function postCurrentUserPreferences(preferences) {
  const { body } = await agent
    .post('/api/users/me/preferences')
    .send(preferences);
  return body;
}

/**
 * GET /api/users/me/historyProducts.
 * Get the list of product history for the current user.
 *
 * @async
 * @param {object} query - Query parameters.
 * @returns {Array<object>} List of product history.
 */
export async function getProductsHistory(query = {}) {
  const { body } = await agent
    .get('/api/users/me/historyProducts')
    .query(query);

  return body;
}

/**
 * POST /api/users/me/historyProducts.
 * Add new entry in the products history list of the current user.
 *
 * @param {string} productId - ID of the product.
 * @returns {object} Http response body.
 */
export async function postProductHistory(productId) {
  const entity = { productId };

  const { body } = await agent
    .post('/api/users/me/historyProducts')
    .send(entity);

  return body;
}

/**
 * GET /api/users/me/createdProducts.
 * Get the list of the created products for the current user.
 *
 * @async
 * @param {object} query - Query parameters.
 * @returns {Array<object>} List of the created products.
 */
export async function getCurrentUserCreatedProducts(query = {}) {
  const { body } = await agent
    .get('/api/users/me/createdProducts')
    .query(query);

  return body;
}

/**
 * GET /api/users/me/productsToValidate.
 * Get the list of the products that must be validated by the current user.
 *
 * @async
 * @param {object} query - Query parameters.
 * @returns {Array<object>} List of the products to be validated.
 */
export async function getProductsToValidate(query = {}) {
  const { body } = await agent
    .get('/api/users/me/productsToValidate')
    .query(query);

  return body;
}

/**
 * GET /api/users/me/followedProducts.
 * Get the list of the followed products for the current user.
 *
 * @async
 * @param {object} query - Query parameters.
 * @returns {Array<object>} List of the followed products.
 */
export async function getCurrentUserFollowedProducts(query = {}) {
  const { body } = await agent
    .get('/api/users/me/followedProducts')
    .query(query);

  return body;
}
